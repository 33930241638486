import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import React, { useState, useEffect, useMemo } from 'react';
import { ORDERS_TAB, ORDERS_TAB_VALUE } from 'src/features/Orders/constant';
import OpenOrderPage from 'src/features/Orders/OpenOrder/OpenOrder';
import OrderHistoryPage from 'src/features/Orders/OrderHistory';
import TradeHistoryPage from 'src/features/Orders/TradeHistory';
import TransactionHistoryPage from 'src/features/Orders/TransactionHistory';
import styles from './index.module.scss';
import { getCookieStorage } from '../../helpers/storage';
import routeConstants from 'src/routes/route.constants';
import { useLocation } from 'react-router-dom';
import { TypeTrade } from 'src/constants/common.constants';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const Orders: React.FC = () => {
  const search = useLocation().search;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [tabActive, setTabActive] = useState(0);
  const history = useHistory();
  const { t } = useTranslation('common');
  const token = getCookieStorage('access_token');

  useEffect(() => {
    const curTab = searchParams.get('tab');
    switch (curTab) {
      case 'open-order':
        setTabActive(0);
        break;
      case 'order-history':
        setTabActive(1);
        break;
      case 'trade-history':
        setTabActive(2);
        break;
      case 'transaction-history':
        setTabActive(3);
        break;

      default:
        break;
    }
  }, [searchParams]);

  useEffect(() => {
    if (!token) {
      window.location.replace(`${process.env.REACT_APP_DOMAIN_SPOT}`);
    }
  }, [token]);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('orders-tab')}>
        {ORDERS_TAB.map((tab, index) => (
          <div
            key={index}
            className={cx('tab-items', tabActive === index && 'tab-items-active')}
            onClick={() => {
              setTabActive(index);
              history.push(routeConstants.OPEN_ORDER + '?tab=' + ORDERS_TAB_VALUE[index]);
            }}
          >
            {t(tab)}
          </div>
        ))}
      </div>
      <div className={cx('orders-content')}>
        {tabActive === 0 && <OpenOrderPage />}
        {tabActive === 1 && <OrderHistoryPage />}
        {tabActive === 2 && <TradeHistoryPage />}
        {tabActive === 3 && <TransactionHistoryPage />}
      </div>
    </div>
  );
};
