import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import axios from "axios";

export const CancelToken = axios.CancelToken;
interface ITransactionHistory {
  id: number;
  time: string;
  amount: string;
  asset: string;
  symbol: string;
  type: string;
}

const initialState = {
  error: '',
  loading: false,
  transactionHistory: { code: 0, data: { list: [] as ITransactionHistory[], count: 0 } },
};

let cancelTransaction: any;

export const getTransactionHistory = createAsyncThunk(
  'get-transaction-history',
  async (params: {
    page?: number;
    size?: number;
    time?: string;
    startTime?: string | number;
    endTime?: string | number;
    asset?: string | null | undefined;
    type?: string | null | undefined;
    contractType: string;
  }) => {
    if (cancelTransaction != undefined) {
      cancelTransaction();
    }
    return await axiosInstance.get(`/transactions`, {
      params,
      timeout: 60000,
      cancelToken: new CancelToken(function executor(c) {
        cancelTransaction = c;
      }),
    });
  },
);

export const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getTransactionHistory.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getTransactionHistory.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getTransactionHistory.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.transactionHistory = action.payload;
    },
  },
});

const { reducer: transactionHistoryReducer } = transactionHistorySlice;

export default transactionHistoryReducer;
