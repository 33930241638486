import { Box } from '@material-ui/core';
import classnames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { iconDown, iconUSDC, iconUSDT } from 'src/assets/icon';
import styles from 'src/features/Market/components/ManageFunds/Transfer.module.scss';
import useOnClickOutside from 'src/hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import { USD } from '../../Constant';
import { getLocalStorage } from 'src/helpers/storage';

type AssetType = {
  name: string;
  label: string;
  key: string;
};

type Props = {
  setCoinSelect: any;
  coinSelect: any;
  theme: string;
  listAssets: AssetType[];
  handleChangeCoin: () => void;
};

const cx = classnames.bind(styles);

export const SelectCoin: React.FC<Props> = ({ coinSelect, setCoinSelect, theme, handleChangeCoin, listAssets }) => {
  const [openSelect, setOpenSelect] = useState(false);
  const masterdata = getLocalStorage('masterdata');
  const coins = masterdata?.coins || null;
  const el = useRef(null);
  const { t } = useTranslation('common');

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const handleTransformIcon = (item: any) => {
    const coinImg = coins.find((coin: any) => coin.coin === item.key.toLowerCase());
    return coinImg?.icon_image || (item.key === USD ? iconUSDC : iconUSDT);
  };

  const handleTransformLabel = (item: any) => {
    const coinImg = coins.find((coin: any) => coin.coin === item.key.toLowerCase());
    return coinImg?.name || 'United State Dollar';
  };

  return (
    <div className={cx('select-wrapper')} ref={el}>
      <div className={cx('select-value')} onClick={() => setOpenSelect(!openSelect)}>
        <Box display="flex" alignItems="center" gridGap={10}>
          <img src={handleTransformIcon(coinSelect)} alt="" width={26} height={26} style={{ objectFit: 'contain' }} />
          <div className={cx('coin-select')}>{coinSelect.name}</div>
          <div className={cx('coin-label')}>{handleTransformLabel(coinSelect)}</div>
        </Box>
        {listAssets.length > 1 && <img className={cx(openSelect && 'rotate')} src={iconDown} />}
      </div>

      {openSelect && listAssets.length > 1 && (
        <div className={cx('select-list')}>
          {listAssets.length > 0 ? (
            listAssets?.map((item, index) => (
              <div
                key={index}
                className={cx('select-item')}
                onClick={() => {
                  setCoinSelect(item);
                  setOpenSelect(false);
                  handleChangeCoin();
                }}
                style={{ cursor: 'pointer' }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gridGap={10}>
                    <img
                      src={handleTransformIcon(item)}
                      alt=""
                      width={26}
                      height={26}
                      style={{ objectFit: 'contain' }}
                    />
                    <div className={cx('coin-select')}>{item.name}</div>
                    <div className={cx('coin-label')}>{handleTransformLabel(item)}</div>
                  </Box>
                  <div></div>
                </Box>
              </div>
            ))
          ) : (
            <div className={cx('select-item')}>{t('pair.no_result_found')}.</div>
          )}
        </div>
      )}
    </div>
  );
};
