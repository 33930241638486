import React from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/TradingRule/TradingRule.module.scss';
import { DemoLine } from './ChartFunding/ChartFunding';
import { useAppSelector } from 'src/store/hooks';
import { formatTimeV7 } from 'src/helpers/stringFormatter';
import { formatIntBalanceV2 } from 'src/helpers/numberFormatter';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const PAGE_SIZE = 6;
const FUNDING_RATE_HEAD = ['orderBook.time', 'tradingRule.funding_interval', 'tradingRule.funding_rate'];

export const FundingRateHistory = (props: any) => {
  const { t } = useTranslation('common');

  const ticker = useAppSelector((state) =>
    state.ticker.tickers.find((ticker) => ticker.symbol === props?.pairSelect.symbol),
  );

  const fundRateValue = useAppSelector((state) => state.general.fundingRateHistory);

  return (
    <div className={cx('funding-wrapper')}>
      <div className={cx('funding-rate')}>
        {t('tradingRule.funding_rate')}: {ticker?.fundingRate}%
      </div>

      <div>
        <DemoLine />
      </div>

      <div className={cx('funding-rate-table')}>
        <table className={cx('table-header')}>
          <thead>
            <tr>
              {FUNDING_RATE_HEAD.map((item, i) => {
                return <th key={i}>{t(item)}</th>;
              })}
            </tr>
          </thead>
          <tbody className={cx('body-table')}>
            {fundRateValue.map((fund, idx) => {
              return (
                <tr key={idx} className={cx('table-row')}>
                  <td className={cx('table-value')}>{formatTimeV7(fund.time)}</td>
                  <td className={cx('table-value')}>{fund.fundingInterval ?? '--'}h</td>
                  <td className={cx('table-value')}>{formatIntBalanceV2(fund.fundingRate, 6)}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={cx('empty-funding')}></div>
    </div>
  );
};
