import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MARGIN_MODE } from 'src/features/Market/components/DialogChooseMarginMode/DialogChooseMarginMode';
import axiosInstance from 'src/helpers/config';
// import axiosInstanceLocal from 'src/helpers/configAPILocal';
import axiosInstanceSpot from 'src/helpers/configAPISpot';
// import { openSnackbarError } from 'src/store/snackbar';
// import store from 'src/store/store';

export type IAccount = {
  id: string;
  asset: string;
  balance: string;
  userId: string;
  operationId: string;
};

const initialState = {
  account: <IAccount[]>[],
  loading: false,
  orderMode: {
    marginMode: MARGIN_MODE.ISOLATE,
    leverage: '20',
  },
  userSetting: {
    limitOrder: false,
    marketOrder: false,
    stopLimitOrder: false,
    stopMarketOrder: false,
    traillingStopOrder: false,
    takeProfitTrigger: false,
    stopLossTrigger: false,
    fundingFeeTrigger: false,
    fundingFeeTriggerValue: 0.25,
  },
  balanceSpot: {
    usdt: {
      available_balance: '0',
    },
    usd: {
      available_balance: '0',
    },
    btc: {
      available_balance: '0',
    },
    eth: {
      available_balance: '0',
    },
    bnb: {
      available_balance: '0',
    },
    sol: {
      available_balance: '0',
    },
    matic: {
      available_balance: '0',
    },
    ltc: {
      available_balance: '0',
    },
    atom: {
      available_balance: '0',
    },
    uni: {
      available_balance: '0',
    },
  },
};

export const getAccount = createAsyncThunk('getAccount', async () => {
  const res = axiosInstance
    .get(`/balance`)
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getAccountByAddress = createAsyncThunk('getAccountByAddress', async (address: string) => {
  const res = axiosInstance
    .get(`/account/address/${address}`)
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getOrderMode = createAsyncThunk('getOrderMode', async (instrumentId: number) => {
  const res = axiosInstance
    .get(`/marginMode`, {
      params: {
        instrumentId: instrumentId,
      },
    })
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getPreference = createAsyncThunk('getPreference', async () => {
  const res = axiosInstance
    .get(`/user-setting/preference`)
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getBalanceSpot = createAsyncThunk('getBalanceSpot', async () => {
  const res = axiosInstanceSpot
    .get(`/balances`)
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<IAccount[] | undefined>) => {
      state.account = action.payload || [];
    },
    updateMode: (state, action) => {
      state.orderMode = action.payload;
    },
  },
  extraReducers: {
    [getAccount.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getAccount.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getAccount.fulfilled.toString()]: (state, action) => {
      const removeLTC_USD =
        action.payload.data && action.payload.data.filter((item: any) => item.asset !== 'LTC' && item.asset !== 'USD');
      state.account = removeLTC_USD || [];
      // state.account = dataFake;
      state.loading = false;
    },
    [getOrderMode.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getOrderMode.rejected.toString()]: (state) => {
      state.orderMode = {
        marginMode: MARGIN_MODE.ISOLATE,
        leverage: '20',
      };
      state.loading = false;
    },
    [getOrderMode.fulfilled.toString()]: (state, action) => {
      state.orderMode = action.payload.data;
      state.loading = false;
    },
    [getPreference.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getPreference.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getPreference.fulfilled.toString()]: (state, action) => {
      state.userSetting = action.payload.data;
      state.loading = false;
    },
    [getBalanceSpot.pending.toString()]: (state) => {
      // state.loading = true;
    },
    [getBalanceSpot.rejected.toString()]: (state) => {
      // state.loading = false;
    },
    [getBalanceSpot.fulfilled.toString()]: (state, action) => {
      state.balanceSpot = action.payload.data.main;
      // state.loading = false;
    },
  },
});

export const { setAccount, updateMode } = accountSlice.actions;

const { reducer: accountReducer } = accountSlice;

export default accountReducer;
