import {
  BoxFavoredList,
  DropDownListWrapper,
  FavoredList,
  FavoredOptionDropdown,
  IconOption,
  LabelOptionDropDown,
} from 'src/features/TradingViewChart/Components/Chart/styles';
import { IconBars, IconCandles, IconHollowCandles, IconHeikinAshi, IconLine, IconArea } from 'src/assets/icon';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { useTranslation } from 'react-i18next';

type DropsDownListType = {
  setIsChooseOption: Dispatch<SetStateAction<number>>;
  isChooseOption: number;
};

const DropDownList: React.FC<DropsDownListType> = ({ setIsChooseOption, isChooseOption }) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation('common');
  const handleClickedChooseOption = useCallback(
    (e: number) => {
      setIsChooseOption(e);
    },
    [setIsChooseOption],
  );
  return (
    <DropDownListWrapper>
      <BoxFavoredList theme={theme}>
        <FavoredList theme={theme}>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(1)}
          >
            <IconOption>
              <IconBars className="icon" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_bars')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(2)}
          >
            <IconOption>
              <IconCandles className="iconCandlesSingle" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_candles')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(3)}
          >
            <IconOption>
              <IconHollowCandles className="iconCandles" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_hollow')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(4)}
          >
            <IconOption>
              <IconHeikinAshi className="icon" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_ashi')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(5)}
          >
            <IconOption>
              <IconLine className="iconLine" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_line')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(6)}
          >
            <IconOption>
              <IconArea className="icon" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>{t('chart_area')}</LabelOptionDropDown>
          </FavoredOptionDropdown>
        </FavoredList>
      </BoxFavoredList>
    </DropDownListWrapper>
  );
};

export default DropDownList;
