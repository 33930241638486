import { useRef } from 'react';
import { RECORDS_PER_PAGE } from 'src/constants/common.constants';
import { getContractType } from 'src/features/PositionAndHistory/helper';
import axiosInstance from 'src/helpers/config';
import { removeAllCookieStorage, setLocalStorage, setOneCookieStorage } from 'src/helpers/storage';
import { errorMessage } from 'src/messages/errorMessages';
import { getAccount, getBalanceSpot, getPreference, setAccount } from 'src/services/account';
import { setFirstLoadFunding } from 'src/services/funding';
import { getAllOpenOrder, getOpenOrder, setFirstLoadOrder } from 'src/services/order';
import { getAllPosition, getPosition, resetInitStatePosition } from 'src/services/position';
import { setFirstLoadFill } from 'src/services/trade';
import { getCurrentUser, setUser } from 'src/services/user';
import { BaseSocket } from 'src/socket/BaseSocket';
import { useAppDispatch } from 'src/store/hooks';
import { noti } from './useNoti';

export const useAuth = (): { login: (token: string) => Promise<void>; logout: () => Promise<void> } => {
  const dispatch = useAppDispatch();
  const isFirstLoad = useRef<any>(false);
  const contractType = getContractType();

  const login = async (token: string | undefined): Promise<void> => {
    if (!token || token === 'undefined') return;

    try {
      // const params = {
      //   token: token,
      // };
      // const res = await axiosInstance.post('/access-token', params);

      // if (res?.data?.info?.message.includes('not found')) {
      //   noti.error(errorMessage.ACCOUNT_NOT_FOUND);
      //   return;
      // }
      const auth = await dispatch(getAccount());
      if (auth?.error?.message) return;

      dispatch(getPreference());
      dispatch(getCurrentUser());
      const payload: any = { page: 1, size: RECORDS_PER_PAGE, contractType };
      !isFirstLoad.current && dispatch(getPosition(payload));
      if (!location.pathname.includes('orders')) {
        const params = { page: 1, size: RECORDS_PER_PAGE };
        const payload = { contractType: contractType };
        !isFirstLoad.current && dispatch(getOpenOrder({ params, payload }));
      }
      !isFirstLoad.current && dispatch(getAllPosition(contractType));
      !isFirstLoad.current && dispatch(getAllOpenOrder(contractType));
      isFirstLoad.current = true;
      dispatch(setFirstLoadFill(true));
      dispatch(setFirstLoadOrder(true));
      dispatch(setFirstLoadFunding(true));
      dispatch(getBalanceSpot());
      setLocalStorage('address', token);
      setOneCookieStorage('isExpired', false);
      // BaseSocket.getInstance().reconnect();
    } catch (error: any) {}
  };

  // logout
  const logout = async (): Promise<void> => {
    removeAllCookieStorage(['access_token', 'refresh_token', 'secret']);
    setOneCookieStorage('isExpired', false);
    setLocalStorage('logout', true);
    dispatch(setAccount(undefined));
    dispatch(setUser(undefined));
    BaseSocket.getInstance().reconnect();
    try {
    } catch (error) {}
  };
  return {
    login,
    logout,
  };
};
