import classNames from 'classnames/bind';
import React from 'react';
import { decoration1 } from 'src/assets/icon';
import styles from './Orders.module.scss';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  label: string;
};

const OrderTitle: React.FC<Props> = ({ title, label }) => {
  return (
    <div className={cx('head-orders')}>
      <div className={cx('head-title')}>{title}</div>
      <div className={cx('head-label')}>{label}</div>
      <img src={decoration1} />
    </div>
  );
};

export default OrderTitle;
