import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { SelectInput } from 'src/components/cores/SelectInput';
import { PriceTypeValue, PriceTypeLabel } from '.';
import { IOrder } from 'src/interfaces/order';
import { useTranslation } from 'react-i18next';

interface TriggerPriceProps {
  index: number;
  isViewOnly: boolean;
  remainingOrders: IOrder[];
  takeProfitPriceInit: any;
  selectPriceTypeOfTakeProfitInit: any;
  selectPriceTypeOfStopLossInit: any;
  handleUpdateSelectPriceType: (value: PriceTypeValue, type: string) => void;
}

const cx = classnames.bind(styles);

const TriggerPrice = (props: TriggerPriceProps) => {
  const {
    index,
    isViewOnly,
    remainingOrders,
    takeProfitPriceInit,
    selectPriceTypeOfTakeProfitInit,
    selectPriceTypeOfStopLossInit,
    handleUpdateSelectPriceType,
  } = props;

  const { t } = useTranslation('common');

  const SELECT_PRICE_TYPE = [
    { value: PriceTypeValue.last, label: t(PriceTypeLabel.last) },
    { value: PriceTypeValue.mark, label: t(PriceTypeLabel.mark) },
  ];

  const [selectPriceTypeOfTakeProfit, setSelectPriceTypeOfTakeProfit] = useState<PriceTypeValue>(
    selectPriceTypeOfTakeProfitInit,
  );
  const [selectPriceTypeOfStopLoss, setSelectPriceTypeOfStopLoss] =
    useState<PriceTypeValue>(selectPriceTypeOfStopLossInit);

  const handleChangeSelectPriceType = (value: PriceTypeValue, type: string) => {
    if (type === 'take-profit') {
      setSelectPriceTypeOfTakeProfit(value);
    } else if (type === 'stop-loss') {
      setSelectPriceTypeOfStopLoss(value);
    }
    handleUpdateSelectPriceType(value, type);
  };

  if (remainingOrders.length === 1) {
    return (
      <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
        <Typography component="p" className={cx('text-order-box')}>
          {t('order.trigger')}
        </Typography>
        <div className={cx('trigger-order', isViewOnly ? 'disabled-select' : '')}>
          <SelectInput
            isDisabled={isViewOnly}
            isFilledArrow={true}
            onChange={(value) => handleChangeSelectPriceType(value, takeProfitPriceInit ? 'take-profit' : 'stop-loss')}
            defaultValue={SELECT_PRICE_TYPE[0]}
            value={{
              value: takeProfitPriceInit ? selectPriceTypeOfTakeProfit : selectPriceTypeOfStopLoss,
              label:
                SELECT_PRICE_TYPE.find(
                  (e) => e.value === (takeProfitPriceInit ? selectPriceTypeOfTakeProfit : selectPriceTypeOfStopLoss),
                )?.label || '',
            }}
            options={SELECT_PRICE_TYPE}
            menuPlacement="bottom"
            className={cx('choose-trigger')}
            classNameWrapper={cx('select-wrapper')}
          />
        </div>
      </Box>
    );
  }

  if (remainingOrders.length === 2) {
    return (
      <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
        <Typography component="p" className={cx('text-order-box')}>
          {t('order.trigger')}
        </Typography>
        <div className={cx('trigger-order', isViewOnly ? 'disabled-select' : '')}>
          <SelectInput
            isDisabled={isViewOnly}
            isFilledArrow={true}
            onChange={(value) => handleChangeSelectPriceType(value, index === 0 ? 'take-profit' : 'stop-loss')}
            defaultValue={SELECT_PRICE_TYPE[0]}
            value={{
              value: index === 0 ? selectPriceTypeOfTakeProfit : selectPriceTypeOfStopLoss,
              label:
                SELECT_PRICE_TYPE.find(
                  (e) => e.value === (index === 0 ? selectPriceTypeOfTakeProfit : selectPriceTypeOfStopLoss),
                )?.label || '',
            }}
            options={SELECT_PRICE_TYPE}
            menuPlacement="bottom"
            className={cx('choose-trigger')}
            classNameWrapper={cx('select-wrapper')}
          />
        </div>
      </Box>
    );
  }

  return null;
};

export default TriggerPrice;
