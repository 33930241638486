import classnames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HistoryDashboard from 'src/features/PositionAndHistory/HistoryDashboard';
import MarginInformation from 'src/features/Market/components/MarginInformation/MarginInformation';
import OrderForm from 'src/features/Market/components/OrderForm';
import OrderbookTrade from 'src/features/OrderbookTrade/OrderbookTrade';
import PairStatistics from 'src/features/Trading/components/PairStatistics';
import Trading from 'src/features/Trading/Trading';
import { setCurrentInstrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';
import styles from './Dashboard.module.scss';
import useIsMobile from 'src/hooks/useIsMobile';
import { MarketDefault, MOBILE_DIMENSION, TypeTrade } from 'src/constants/common.constants';
import PairStatisticsMobile from 'src/features/Trading/components/PairStatisticMobile';
import { changeTypeTrade } from 'src/services/typeTrade';

const cx = classnames.bind(styles);

const Dashboard: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ instrument: string; typeTrade: string }>();
  const instrument = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector((state) => state.instrument.currentInstrument);
  const isMobile = useIsMobile(MOBILE_DIMENSION);

  // watch change instrument - url
  useEffect(() => {
    const instrumentNew = instrument.filter((item) => item.contractType === params.typeTrade);
    if (
      !params.instrument &&
      location.pathname.includes('futures') &&
      instrumentNew?.length &&
      instrumentNew[0].symbol &&
      currentInstrument.symbol === ''
    ) {
      const instru = instrumentNew.find((e) =>
        params.typeTrade === TypeTrade.USD_M ? e.symbol === MarketDefault.BTCUSDT : e.symbol === MarketDefault.BTCUSDM,
      );
      dispatch(setCurrentInstrument(instru || instrumentNew[0]));
      history.push(`/futures/${params.typeTrade}/${instru?.symbol || instrumentNew[0].symbol}`);
    } else if (params.instrument) {
      const instru = instrumentNew.find((e) => e.symbol === params.instrument);
      if (instru) {
        dispatch(setCurrentInstrument(instru));
        history.push(`/futures/${params.typeTrade}/${instru.symbol}`);
      } else if (instrument[0].symbol) {
        // history.push(`${routeConstants.NOT_FOUND}`);
      }
    } else if (currentInstrument.symbol !== '') {
      history.push(`/futures/${params.typeTrade}/${currentInstrument.symbol}`);
    }

    if (params.typeTrade) {
      dispatch(changeTypeTrade(params.typeTrade === TypeTrade.COIN_M));
    }
  }, [instrument]);

  return (
    <>
      <div className={cx('future-wrapper')}>
        <div className={cx('future-wrapper-left')}>
          <div className={cx('trading-wrapper')}>
            <PairStatistics />
            <PairStatisticsMobile />
          </div>
          <div className={cx('dashboard')}>
            <div className={cx('trading')}>
              <Trading />
            </div>
            <div className={cx('order-book')}>
              <OrderbookTrade />
            </div>
          </div>
          {!isMobile && <HistoryDashboard />}
        </div>
        <div className={cx('market-order')}>
          <OrderForm />
          <MarginInformation />
        </div>
        {isMobile && <HistoryDashboard />}
      </div>
    </>
  );
};

export default Dashboard;
