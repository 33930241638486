import { Box } from '@material-ui/core';
import classnames from 'classnames/bind';
import React, { FC, useEffect, useRef, useState } from 'react';
import Loading from 'src/components/Loading';
import styles from 'src/features/PositionAndHistory/styles/Order.module.scss';
import { formatTime } from 'src/helpers/stringFormatter';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import DatePicker from 'src/components/cores/DatePicker/DatePicker';
import { DateRange } from '@material-ui/pickers';
import { listTimeSelect, SelectTimeValue, TransactionType } from './constant';
import { getTransactionHistory } from 'src/services/transaction-history';
import moment from 'moment';
import { Pagination } from '@mui/material';
import { handleDirect } from './PositionRecord';
import { formatOrderEnum } from 'src/helpers/numberFormatter';
import { getCookieStorage } from 'src/helpers/storage';
import TooltipNumber from 'src/components/cores/Tooltip';
import { formatOrderText, getContractType, getSymbolName } from './helper';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'src/hooks/useScreen';
import { THEME_MODE } from 'src/interfaces/theme';
import { noData, noDataDark } from 'src/assets/icon';
import TextNumber from 'src/components/cores/TextNumber';

interface ITransactionHistory {
  id: number;
  time: string;
  amount: string;
  asset: string;
  symbol: string;
  type: string;
}

const cx = classnames.bind(styles);

const RECORDS_PER_PAGE = 6;

const TransactionHistory: FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<number | null>(0);
  const [tabSelectValue, setTabSelectValue] = useState<string | null>(SelectTimeValue.ONE_DAY);

  const { loading, transactionHistory } = useAppSelector((state) => state.transactionHistory);
  const account = useAppSelector((state) => state.account.account);

  const [rows, setRows] = useState<ITransactionHistory[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const selectedDatePickerRef = useRef<DateRange<Date>>([null, null]);
  const [selectedDatePicker, setSelectedDatePicker] = useState<DateRange<Date>>([null, null]);
  const [isChangeSelectDatePicker, setIsChangeSelectDatePicker] = useState<boolean>(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const token = getCookieStorage('access_token');
  const isExpired = getCookieStorage('isExpired');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined';
  const contractType = getContractType();
  const { t } = useTranslation('common');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const size = useWindowSize();

  const handleFormatTime = () => {
    let formatStartTime;
    let formatEndTime;
    if (tabSelectValue === SelectTimeValue.ONE_DAY) {
      formatStartTime = moment().startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_WEEK) {
      formatStartTime = moment().subtract(1, 'weeks').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_MONTH) {
      formatStartTime = moment().subtract(1, 'months').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.THREE_MONTHS) {
      formatStartTime = moment().subtract(3, 'months').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    }
    return {
      formatStartTime,
      formatEndTime,
    };
  };

  const fetchData = async (page: number) => {
    let isChooseDatePicker = isChangeSelectDatePicker;
    if (!selectedDatePicker[0] || !selectedDatePicker[1]) {
      isChooseDatePicker = false;
    }
    const { formatStartTime, formatEndTime } = handleFormatTime();
    const formatStartDatePicker = moment(selectedDatePicker[0]).startOf('day').utc().toDate().getTime();
    const formatEndDatePicker = moment(selectedDatePicker[1]).endOf('day').utc().toDate().getTime();

    const payload = Object.assign(
      { page: page, size: RECORDS_PER_PAGE, contractType },
      formatStartTime && !isChooseDatePicker && { startTime: formatStartTime },
      formatEndTime && !isChooseDatePicker && { endTime: formatEndTime },
      formatStartDatePicker && isChooseDatePicker && { startTime: formatStartDatePicker },
      formatEndDatePicker && isChooseDatePicker && { endTime: formatEndDatePicker },
    );

    await dispatch(getTransactionHistory(payload));
  };

  useEffect(() => {
    if (transactionHistory.data.list) {
      setRows(transactionHistory.data.list);
      setTotalPage(Math.ceil(transactionHistory.data.count / RECORDS_PER_PAGE));
    }
  }, [transactionHistory.data, account[0]?.id]);

  useEffect(() => {
    if (!isChangeSelectDatePicker && isAuthenticated) {
      fetchData(currentPage);
    }
  }, [tabSelectValue]);

  useEffect(() => {
    if (
      !isOpenDatePicker &&
      isChangeSelectDatePicker &&
      selectedDatePicker[0] &&
      selectedDatePicker[1] &&
      isAuthenticated
    ) {
      setTab(null);
      setTabSelectValue(null);
      setCurrentPage(1);
      fetchData(1);
    }
  }, [isOpenDatePicker, isChangeSelectDatePicker, selectedDatePicker]);

  const handleChangeDatePicker = (value: DateRange<Date>) => {
    setSelectedDatePicker(value);
  };

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDatePickerRef.current[0]).format('YYYY-MM-DD') !==
        moment(selectedDatePicker[0]).format('YYYY-MM-DD') ||
      moment(selectedDatePickerRef.current[1]).format('YYYY-MM-DD') !==
        moment(selectedDatePicker[1]).format('YYYY-MM-DD')
    ) {
      setIsChangeSelectDatePicker(true);
    } else {
      setIsChangeSelectDatePicker(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDatePickerRef.current = selectedDatePicker;
    setIsOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setIsOpenDatePicker(false);
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDatePicker]);

  // const handleSearchHistory = async () => {
  //   if (isChangeSelectDatePicker) {
  //     setTab(null);
  //     setTabSelectValue(null);
  //   }
  //   setCurrentPage(1);
  //   fetchData(1);
  // };

  const handleReset = () => {
    setTab(0);
    setTabSelectValue(SelectTimeValue.ONE_DAY);
    setIsChangeSelectDatePicker(false);
    setCurrentPage(1);
    fetchData(1);
  };

  const renderType = (transaction: ITransactionHistory) => {
    if (transaction.type === TransactionType.DEPOSIT || transaction.type === TransactionType.WITHDRAWAL) {
      return t('order.transfer');
    }
    if (transaction.type === TransactionType.REALIZED_PNL) {
      return t('order.realized_pnl');
    }

    return t(formatOrderText(transaction.type));
  };

  const renderAmount = (transaction: ITransactionHistory) => {
    if (transaction.type === TransactionType.WITHDRAWAL) {
      // return <TooltipNumber defaultValue={`-${transaction.amount}`} characters={20} decimal={8} />;
      return <TextNumber defaultValue={`-${transaction.amount}`} isCoinM={false} />;
    }

    // return <TooltipNumber defaultValue={transaction.amount} characters={20} decimal={8} />;
    return <TextNumber defaultValue={transaction.amount} isCoinM={false} />;
  };

  const renderSymbol = (transaction: ITransactionHistory) => {
    if (transaction.type === TransactionType.DEPOSIT || transaction.type === TransactionType.WITHDRAWAL) {
      return transaction.asset;
    }

    return getSymbolName(transaction.symbol);
  };

  return (
    <div className={'height-100'}>
      {isAuthenticated && (
        <Box>
          <div className={cx('container')}>
            <div className={cx('tab-wrapper')}>
              {listTimeSelect.map((item: { label: string; value: string }, index: number) => {
                return (
                  <div
                    key={item?.label}
                    onClick={() => {
                      setTab(index);
                      setTabSelectValue(item.value);
                      setIsChangeSelectDatePicker(false);
                      setCurrentPage(1);
                    }}
                    className={tab === index ? cx('tab', 'active') : cx('tab')}
                  >
                    {t(item?.label)}
                  </div>
                );
              })}
              <div className={cx('text-time')}>{t('order.time')}</div>
              <DatePicker
                handleChangeDate={handleChangeDatePicker}
                handleOpenDate={handleOpenDatePicker}
                handleCloseDate={handleCloseDatePicker}
                customInput={cx('input-time')}
                isChangeSelectDatePicker={isChangeSelectDatePicker}
              />
              <div>
                {/* <button className={cx('search-btn')} onClick={handleSearchHistory}>
                  Search
                </button> */}
                <button className={cx('reset-btn')} onClick={handleReset}>
                  {t('order.reset')}
                </button>
              </div>
            </div>
          </div>
        </Box>
      )}

      <div className={cx('tbl_data')}>
        {!isAuthenticated && (
          <div className={cx('no-orders')}>
            <div className={cx('warning-table')}>
              <strong onClick={() => handleDirect('login')}>{t('order.login')}</strong> {t('order.or')}{' '}
              <strong onClick={() => handleDirect('signup')}>{t('order.signup')}</strong> {t('order.to_start_trading')}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={'height-100'} style={{ position: 'relative' }}>
            <div className={cx('table-content')} style={{ minHeight: '395px' }}>
              <div
                className={cx('no-data')}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '25%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {!loading && !rows.length && (
                  <>
                    {theme === THEME_MODE.LIGHT ? (
                      <img src={noData} width={74} height={85} />
                    ) : (
                      <img src={noDataDark} width={74} height={85} />
                    )}
                    <div className={cx('no-data-desc')}>{t('order.no_trades')}</div>
                  </>
                )}

                {loading && <Loading />}
              </div>

              <table className={cx('dex_table', 'text-table-xs')}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '340px', width: '22%' }}>{t('order.time')}</th>
                    <th style={{ minWidth: '320px', width: '22%' }}>{t('order.type')}</th>
                    <th style={{ minWidth: '310px', width: '21%' }}>{t('order.amount')}</th>
                    <th style={{ minWidth: '285px', width: '20%' }}>{t('order.asset')}</th>
                    <th style={{ minWidth: '110px', width: '9%' }}>{t('order.symbol')}</th>
                  </tr>
                </thead>
                <tbody className={cx('dex_table__scrollbar', 'overflow-tbody')} style={{ maxHeight: 210 }}>
                  {loading && <div style={{ height: 230 }}></div>}
                  {!loading && !rows.length && <div style={{ height: 230 }}></div>}

                  {!loading && !!rows.length && (
                    <>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className={cx('text-value')}>{formatTime(row.time)}</td>
                          <td className={cx('text-value')}>{renderType(row)}</td>
                          <td className={cx('text-value')}>{renderAmount(row)}</td>
                          <td className={cx('text-value')}>{row.asset ?? '--'}</td>
                          <td className={cx('text-value')}>{renderSymbol(row)}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {!loading && totalPage > 1 && (
                <div className={cx('pagination')}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, page) => {
                      setCurrentPage(page);
                      fetchData(page);
                    }}
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    className={cx('pagination-custom')}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'var(--color-value-1) !important',
                      },
                      '& .Mui-selected': {
                        backgroundColor: 'var(--color-background-pagination) !important',
                        border: '1px solid var(--color-outline-pagination) !important',
                        color: 'var(--color-text-pagination) !important',
                        borderRadius: '5px !important',
                      },
                      '& .MuiPagination-ul': {
                        flexWrap: 'nowrap',
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default TransactionHistory;
