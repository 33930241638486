import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import { IFill } from 'src/interfaces/fill';
import { IFunding } from 'src/interfaces/funding';
import { IOrder, OrderStatus } from 'src/interfaces/order';
import { IPosition } from 'src/services/position';

const initialState = {
  error: '',
  loading: false,
  orders: { code: 0, data: [] as IOrder[], metadata: { totalPage: 1 } },
  positions: { code: 0, data: [] as IPosition[], metadata: { totalPage: 1 } },
  trades: { code: 0, data: [] as IFill[], metadata: { totalPage: 1 } },
  fundings: { code: 0, data: [] as IFunding[], metadata: { totalPage: 1 } },
};

export const cancelOrder = createAsyncThunk('account/cancel-order', async (id: number) => {
  return await axiosInstance.delete(`/order/${id}`);
});

export const cancelAllOrder = createAsyncThunk('account/cancel-all', async () => {
  return await axiosInstance.delete(`/order/all`);
});

export const accountOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: {
    [`${cancelOrder.fulfilled}`]: (state, action) => {
      const orderId = action.payload.data.id;
      const order = state.orders.data.find((item) => item.id === orderId);
      if (order) {
        order.status = OrderStatus.CANCELED;
      }
    },
    [`${cancelAllOrder.fulfilled}`]: (state) => {
      state.orders.data
        .filter((order) =>
          [OrderStatus.ACTIVE, OrderStatus.PENDING, OrderStatus.UNTRIGGERED].includes(order.status as OrderStatus),
        )
        .forEach((order) => (order.status = OrderStatus.CANCELED));
    },
  },
});

const { reducer: accountOverviewReducer } = accountOrderSlice;

export default accountOverviewReducer;
