import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CInput } from 'src/components/cores/Input';
import BigNumber from 'bignumber.js';
import { countCharacter, formatNumber, handleNumber } from 'src/helpers/numberFormatter';
import { useAppSelector } from 'src/store/hooks';
import useScreen from 'src/hooks/useScreen';
import { validateNumber } from './constants';
import { SliderRange } from 'src/components/SliderRange/SliderRangeMUI';
import TooltipText from 'src/components/cores/TooltipText';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { TypeTrade } from 'src/constants/common.constants';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

export const PNLCalculator = (props: any) => {
  const leverageMarginCache = useAppSelector((state) => state.masterdataFuture.leverageMarginCache);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);

  const [isLong, setIsLong] = useState(true);
  const [entryPrice, setEntryPrice] = useState('');
  const [exitPrice, setExitPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [leverage, setLeverage] = useState(20);
  const [result, setResult] = useState({ initialMargin: '', PNL: '', ROE: '' });
  const { t } = useTranslation('common');

  const { isMobile } = useScreen();

  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const maxLeverage = tradingRulesCache?.filter((item) => item.symbol === props?.pair.symbol);
  const levelOpen = leverageMarginCache?.filter(
    (item) => leverage <= item.maxLeverage && props?.pair.symbol === item?.symbol,
  );

  const leveOpenSort = levelOpen.sort(function (a, b) {
    return a.maxLeverage - b.maxLeverage;
  });

  const handleCalculator = () => {
    const side = isLong ? 1 : -1;
    let initialMargin, PNL, ROE;
    if (props?.pair?.contractType === TypeTrade.COIN_M) {
      // Initial Margin:	Quantity * Contract Multiplier / (Entry price * Leverage)
      // PNL:	Quantity * Contract Multiplier * (1/ Entry price - 1/ Exit price) * Side
      // ROE:	PNL / Initial Margin * 100%
      const multiplier = props?.pair?.multiplier || 100;
      initialMargin = new BigNumber(quantity).times(multiplier).dividedBy(new BigNumber(entryPrice).times(leverage));
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseExit = new BigNumber(1).dividedBy(exitPrice);

      PNL = new BigNumber(quantity).times(multiplier).times(side).times(inverseEntry.minus(inverseExit));
      ROE = PNL.div(initialMargin).times(100);
    } else {
      // IMR = 1 / leverage
      // Initial Margin = Quantity * Entry Price * IMR
      // PnL :
      // Long = (Exit Price - Entry Price) * Quantity
      // Short = (Entry Price - Exit Price) * Quantity
      // ROE% = PnL / Initial Margin
      const IMR = new BigNumber(1).div(leverage);

      initialMargin = new BigNumber(quantity).times(entryPrice).times(IMR);

      PNL = new BigNumber(exitPrice).minus(entryPrice).times(quantity).times(side);

      ROE = PNL.div(initialMargin).times(100);
    }
    setResult({
      initialMargin: formatNumber(initialMargin.toString(), 2, '-'),
      PNL: formatNumber(PNL.toString(), 2, '-'),
      ROE: formatNumber(ROE.toString(), 2, '-'),
    });
  };

  useEffect(() => {
    setLeverage(20);
  }, [props?.pair]);

  return (
    <div className={cx(isMobile ? 'wrapper-content-mb' : 'wrapper-content')}>
      {/* Content Left */}
      <div className={cx(isMobile ? 'wrapper-content-mb__left' : 'wrapper-content__left')}>
        <div className={cx(isMobile ? 'wrapper-content-mb__left__type-trade' : 'wrapper-content__left__type-trade')}>
          <div onClick={() => setIsLong(true)} className={cx(isLong && 'type-active')}>
            {t('calculator.long')}
          </div>
          <div onClick={() => setIsLong(false)} className={cx(!isLong && 'type-active-short')}>
            {t('calculator.short')}
          </div>
        </div>

        {isMobile && <div className={cx('mt-52')}></div>}

        {/* <SliderRange
          handleRange={(value: number) => setLeverage(value)}
          value={leverage || 20}
          isShowValue={true}
          maxRange={maxLeverage[0]?.maxLeverage || 125}
        /> */}

        <SliderRange
          value={leverage || 20}
          handleRange={(value: number) => setLeverage(value)}
          maxRange={maxLeverage[0]?.maxLeverage || 125}
          type="calculator"
        />
        {leverage > 1 && (
          <>
            {' '}
            <div className={cx(isMobile ? 'text-medium-thin-mb' : 'text-medium-thin')}>
              {t('trading.max_position')}:{' '}
            </div>
            <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')} style={{ marginTop: '6px' }}>
              {formatNumber(leveOpenSort[0]?.max.toString(), 2, '0')}{' '}
              {isCoinM ? props.pair?.rootSymbol : props.pair?.quoteCurrency}
            </div>
          </>
        )}

        {/* Entry Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.entry_price')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : 'input-desk')}
              type="text"
              placeholder={t('calculator.input_price')}
              size="sm"
              outline={false}
              valueHandSet={entryPrice}
              onChange={(value: string): void => {
                validateNumber(value, setEntryPrice, 10, 2);
              }}
            />
            <div className={cx('token-symbol')}>{props.pair?.quoteCurrency}</div>
          </div>
        </div>

        {/* Exit Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('calculator.exit_price')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_price')}
              size="sm"
              outline={false}
              valueHandSet={exitPrice}
              onChange={(value: string): void => {
                validateNumber(value, setExitPrice, 10, 2);
              }}
            />
            <div className={cx('token-symbol')}>{props.pair?.quoteCurrency}</div>
          </div>
        </div>

        {/* Quantity */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.quantity')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_quantity')}
              size="sm"
              outline={false}
              valueHandSet={quantity}
              onChange={(value: string): void => {
                validateNumber(value, setQuantity, 10, 3);
              }}
            />
            <div className={cx('token-symbol')}>{isCoinM ? 'Cont' : props.pair?.rootSymbol}</div>
          </div>
        </div>

        {/* Button Calculate */}

        {!isMobile && (
          <>
            <button
              className={cx('button-calculate')}
              disabled={
                !entryPrice ||
                !quantity ||
                !exitPrice ||
                (new BigNumber(entryPrice).times(quantity).gt(leveOpenSort[0]?.max) && leverage > 1)
              }
              onClick={handleCalculator}
            >
              {t('calculator.calculate')}
            </button>
            {new BigNumber(entryPrice).times(quantity).gt(leveOpenSort[0]?.max) && leverage > 1 && (
              <div className={cx('error-maximum')}>{t('calculator.exceeded')}</div>
            )}
          </>
        )}
      </div>

      {/* Content Right */}

      <div className={cx(isMobile ? 'wrapper-content-mb__right' : 'wrapper-content__right')}>
        <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')}>{t('calculator.result')}</div>
        <div className={cx('result-content')}>
          <TooltipText text={t('calculator.tooltip_initial_margin')}>
            <div className={cx(isMobile ? 'text-result-mb' : 'text-result')}>{t('calculator.initial_margin')}</div>
          </TooltipText>

          <TooltipText text={countCharacter(result.initialMargin) > 10 ? result.initialMargin : ''}>
            <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
              <div className={cx(result.initialMargin.length > 0 ? 'text-value' : 'text-value-empty')}>
                {handleNumber(result.initialMargin, 10) || '-'}
              </div>
              <div className={cx(result.initialMargin.length > 0 ? '' : 'text-pair')}>{props.pair?.quoteCurrency}</div>
            </div>
          </TooltipText>
        </div>
        <div className={cx('result-content')}>
          <div className={cx(isMobile ? 'text-result-mb' : 'text-result')}>{t('calculator.pnl')}</div>

          <TooltipText text={countCharacter(result.PNL) > 10 ? result.PNL : ''}>
            <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
              <div className={cx(result.PNL.length > 0 ? 'text-value' : 'text-value-empty')}>
                {handleNumber(result.PNL, 10) || '-'}
              </div>
              <div className={cx(result.PNL.length > 0 ? '' : 'text-pair')}>{props.pair?.quoteCurrency}</div>
            </div>
          </TooltipText>
        </div>
        <div className={cx('result-content')}>
          <div className={cx(isMobile ? 'text-result-mb' : 'text-result')}>{t('calculator.roe')}</div>

          <TooltipText text={countCharacter(result.ROE) > 10 ? result.ROE : ''}>
            <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
              <div className={cx(result.ROE.length > 0 ? 'text-value' : 'text-value-empty')}>
                {handleNumber(result.ROE, 10) || '-'}
              </div>
              <div className={cx(result.ROE.length > 0 ? '' : 'text-pair')}>%</div>
            </div>
          </TooltipText>
        </div>
      </div>
      {isMobile && (
        <>
          <button
            className={cx('button-calculate')}
            disabled={
              !entryPrice ||
              !quantity ||
              !exitPrice ||
              (new BigNumber(entryPrice).times(quantity).gt(leveOpenSort[0]?.max) && leverage > 1)
            }
            onClick={handleCalculator}
          >
            {t('calculator.calculate')}
          </button>
          {new BigNumber(entryPrice).times(quantity).gt(leveOpenSort[0]?.max) && leverage > 1 && (
            <div className={cx('error-maximum')}>{t('calculator.exceeded')}</div>
          )}
        </>
      )}
    </div>
  );
};
