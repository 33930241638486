import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_vn from './translations/vi/common.json';
import common_en from './translations/en/common.json';
import common_kr from './translations/ko/common.json';

i18n
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'en',
    fallbackNS: 'translation',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: common_en,
      },
      vi: {
        common: common_vn,
      },
      ko: {
        common: common_kr,
      },
    },
  });

export default i18n;
