import { createSlice } from '@reduxjs/toolkit';

const initialState: { isCoinM: boolean } = {
  isCoinM: false,
};

export const typeTradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    changeTypeTrade: (state, action) => {
      state.isCoinM = action.payload;
    },
  },
});

const { reducer: typeTradeReducer } = typeTradeSlice;

export default typeTradeReducer;

export const { changeTypeTrade } = typeTradeSlice.actions;
