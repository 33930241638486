import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

type Props = {
  value: number;
  handleRange: (value: number) => void;
  maxRange?: number;
  type?: string;
};

const LIST_PERCENT = [
  {
    value: 0,
    label: '',
  },
  {
    value: 24,
    label: '',
  },
  {
    value: 49,
    label: '',
  },
  {
    value: 74,
    label: '',
  },
  {
    value: 99,
    label: '',
  },
];

function valueLabelFormat(value: number) {
  return value + 'x';
}

function valueLabelFormatSize(value: number) {
  return value + '%';
}

export const handleMaxLeverage = (max: number | undefined) => {
  if (!max) {
    return LIST_PERCENT;
  }
  const distance = Number(max) / 5;
  const arrayRange = [{ value: 1, label: '1x' }];
  const tolerance = max > 100 ? 1 : 0.5;

  for (let i = 0; i <= Number(max) - distance; i = i + distance) {
    arrayRange.push({ value: i + distance - tolerance, label: i + distance + 'x' });
  }
  return arrayRange;
};

const SliderCustom = styled(Slider)({
  color: 'var(--switch-disable)',
  borderRadius: 'none',
  height: '2px',
  opacity: 1,
  '& .MuiSlider-mark': {
    width: '10px',
    height: '10px',
    borderRadius: ' 50%',
    border: '1.5px solid var(--switch-disable)',
    opacity: 1,
    backgroundColor: 'var(--bg-modal-pre)',
  },

  '& .MuiSlider-markActive': {
    border: '1.5px solid var(--color-primary-1)',
  },

  '&. MuiSlider-markLabel': {
    color: 'var(--text-leverange)',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: 'var(--switch-disable)',
    height: '2px',
  },

  '& .MuiSlider-track': {
    backgroundColor: 'var(--color-primary-1)',
    border: 'none',
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'var(--bg-modal-pre)',
    width: '16px',
    height: '16px',
    border: '3px solid var(--color-primary-1)',

    '&:hover': {
      boxShadow: 'none',
    },
  },
});

const SizeSlider = styled(SliderCustom)({
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'var(--color-tooltip)',
    color: 'var(--color-white)',
    borderRadius: '8px',
    transformOrigin: 'bottom left',
    '&:before': { display: 'none' },
    padding: '5px',
  },
});

const LeverageSlider = styled(SliderCustom)({
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'transparent',
    color: 'var(--text-tab-active)',
    fontSize: '16px',
    padding: '0px',
  },
});

export const SliderRange = ({ value, handleRange, maxRange, type }: Props) => {

  
  return (
    <Box sx={{ width: '96%', marginBottom: '20px', marginLeft: '9px' }}>
      {type === 'size' && (
        <SizeSlider
          aria-label="custom thumb label"
          defaultValue={value}
          step={1}
          min={0}
          max={maxRange || 100}
          marks={handleMaxLeverage(maxRange)}
          valueLabelFormat={valueLabelFormatSize}
          valueLabelDisplay="auto"
          value={value}
          onChange={(e, newValue: number | number[]) => handleRange(Number(newValue))}
        />
      )}
      {type === 'ajust' && (
        <SizeSlider
          aria-label="custom thumb label"
          defaultValue={value}
          step={1}
          min={1}
          max={maxRange || 125}
          marks={handleMaxLeverage(maxRange)}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="auto"
          value={value}
          onChange={(e, newValue: number | number[]) => handleRange(Number(newValue))}
        />
      )}
      {type === 'calculator' && (
        <LeverageSlider
          aria-label="custom thumb label"
          defaultValue={value}
          step={1}
          min={1}
          max={maxRange || 125}
          marks={handleMaxLeverage(maxRange)}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="on"
          value={value}
          onChange={(e, newValue: number | number[]) => handleRange(Number(newValue))}
        />
      )}
    </Box>
  );
};
