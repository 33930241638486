import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  EnctypeArrowDownIcon,
  EnctypeArrowUpIcon,
  IconDot,
  IconZoomOut,
  LineChartUpIcon,
  RoundZoomOutIcon,
  SettingFilledIcon,
  iconZoomOutDark,
  iconZoomOut,
  iconZoomInDark,
  iconZoomIn,
  downFillDark,
  downFillLight,
} from 'src/assets/icon';
import { IChartingLibraryWidget } from 'src/charting_library/charting_library.min';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import DropDownBase from 'src/features/TradingViewChart/Components/Chart/components/DropDownBase';
import DropDownList from 'src/features/TradingViewChart/Components/Chart/components/DropDownList';
import IconBox from 'src/features/TradingViewChart/Components/Chart/components/IconBox';
import {
  DEPTH_CHART,
  TRADING_VIEW_CHART,
  dataHours,
  dataMinutes,
  TIME_WEBVIEW,
} from 'src/features/TradingViewChart/Components/Chart/constant';
import {
  ChartContent,
  ChartOption,
  ChartType,
  ChartTypeDepth,
  ChartTypeTradingView,
  ChartWrapper,
  DepthOption,
  FullScreen,
  IconRotateFill,
  Label,
  LabelTimeFill,
  Line,
  MoreOptionBox,
  Option,
  OptionCustomHours,
  OptionCustomMinute,
  OptionDay,
  OptionDot,
  OptionMonth,
  OptionsWebview,
  OptionWeek,
  OverLay,
  TimeOption,
  TimeValue,
} from 'src/features/TradingViewChart/Components/Chart/styles';
import HighChart from 'src/features/TradingViewChart/Components/HighChart';
import TradingView from 'src/features/TradingViewChart/Components/TradingView/TradingView';
import useIsMobile from 'src/hooks/useIsMobile';
import { THEME_MODE } from 'src/interfaces/theme';
import { useAppSelector } from 'src/store/hooks';

const ChartIndex: React.FC = () => {
  const [time, setTime] = useState<string>(window.localStorage.getItem('tradingview.time') || '60');
  const [isActive, setIsActive] = useState<string>('');
  const [isShowFavoredList, setIsShowFavoredList] = useState<boolean>(false);
  const [isShowFavoredListMinute, setIsShowFavoredListMinute] = useState<boolean>(false);
  const [isShowFavoredListHours, setIsShowFavoredListHours] = useState<boolean>(false);
  const [isChooseOption, setIsChooseOption] = useState<number>(0);
  const [tradingViewChart, setTradingViewChartParent] = useState<IChartingLibraryWidget>();
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const [chartActive, setChartActive] = useState(TRADING_VIEW_CHART);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isShowMoreOption, setIsShowMoreOption] = useState<boolean>(false);
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const location = useLocation();
  const { t } = useTranslation('common');

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const renderLabelMinute = (time: string) => {
    switch (time) {
      case '1':
        return `1 ${t('tradingView.minute_ops')}`;
      case '3':
        return `3 ${t('tradingView.minutes_ops')}`;
      case '5':
        return `5 ${t('tradingView.minutes_ops')}`;
      case '15':
        return `15 ${t('tradingView.minutes_ops')}`;
      case '30':
        return `30 ${t('tradingView.minutes_ops')}`;
      default:
        return `${t('tradingView.minute')}`;
    }
  };

  const renderLabelHour = (time: string) => {
    switch (time) {
      case '60':
        return `1 ${t('tradingView.hour_ops')}`;
      case '120':
        return `2 ${t('tradingView.hours')}`;
      case '240':
        return `4 ${t('tradingView.hours')}`;
      case '360':
        return `6 ${t('tradingView.hours')}`;
      case '720':
        return `12 ${t('tradingView.hours')}`;
      default:
        return `${t('tradingView.hour')}`;
    }
  };

  const handleClickActive = useCallback(
    (e: string) => {
      setIsActive(e);
      setTime('');
    },
    [setIsActive],
  );

  const handleClickFullscreen = useCallback(() => {
    fullScreenRef?.current?.requestFullscreen();
  }, [fullScreenRef?.current]);

  const handleClickExitFullscreen = useCallback(
    (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        document?.exitFullscreen();
      }
    },
    [document],
  );

  const onClose = () => {
    setIsShowFavoredListMinute(false);
    setIsShowFavoredListHours(false);
    setIsShowFavoredList(false);
  };

  const closeAll = () => {
    setIsShowFavoredListMinute(false);
    setIsShowFavoredListHours(false);
    setIsShowFavoredList(false);
  };

  const handleClickShowExpand = () => {
    setIsShowMoreOption(!isShowMoreOption);
    closeAll();
  };

  return (
    <ChartWrapper ref={fullScreenRef}>
      <FullScreen>
        <ChartOption theme={theme} webview={location.pathname.includes('webview')}>
          {chartActive === TRADING_VIEW_CHART && (
            <>
              <TimeOption>
                {(isShowFavoredListMinute || isShowFavoredListHours || isShowFavoredList || isShowMoreOption) && (
                  <OverLay onClick={onClose} />
                )}

                {location.pathname.includes('webview') ? (
                  <OptionsWebview>
                    {TIME_WEBVIEW.map((item, index: number) => (
                      <>
                        <TimeValue
                          isActive={time === item.value}
                          onClick={() => {
                            setTime(item.value);
                          }}
                          key={index}
                        >
                          {item.label}
                        </TimeValue>
                        <Line />
                      </>
                    ))}
                    <DepthOption
                      onClick={() => {
                        setChartActive(DEPTH_CHART);
                      }}
                      chartActive={chartActive}
                    >
                      {t('tradingView.depth')}
                    </DepthOption>
                  </OptionsWebview>
                ) : (
                  <>
                    <OptionCustomMinute
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredListMinute(!isShowFavoredListMinute);
                      }}
                    >
                      <LabelTimeFill>{renderLabelMinute(time)}</LabelTimeFill>
                      <IconRotateFill isShowFavoredListMinute={isShowFavoredListMinute}>
                        <img
                          src={theme === THEME_MODE.LIGHT ? downFillLight : downFillDark}
                          className="iconArrowDownFill"
                        />
                      </IconRotateFill>
                      {isShowFavoredListMinute && (
                        <DropDownBase
                          time={time}
                          isShowFavoredListMinutes={isShowFavoredListMinute}
                          isShowFavoredListHours={isShowFavoredListHours}
                          setIsActive={setIsActive}
                          setTime={setTime}
                          data={dataMinutes}
                        />
                      )}
                    </OptionCustomMinute>
                    <OptionCustomHours
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredListHours(!isShowFavoredListHours);
                      }}
                    >
                      <LabelTimeFill>{renderLabelHour(time)}</LabelTimeFill>
                      <IconRotateFill isShowFavoredListHours={isShowFavoredListHours}>
                        <img
                          src={theme === THEME_MODE.LIGHT ? downFillLight : downFillDark}
                          className="iconArrowDownFill"
                        />
                      </IconRotateFill>
                      {isShowFavoredListHours && (
                        <DropDownBase
                          isShowFavoredListMinutes={isShowFavoredListMinute}
                          isShowFavoredListHours={isShowFavoredListHours}
                          time={time}
                          setTime={setTime}
                          data={dataHours}
                          setIsActive={setIsActive}
                        />
                      )}
                    </OptionCustomHours>
                    <OptionDay
                      isActive={isActive}
                      onClick={() => {
                        closeAll();
                        handleClickActive('1D');
                        setTime('1D');
                      }}
                    >
                      <Label theme={theme}>1D</Label>
                    </OptionDay>
                    <OptionWeek
                      isActive={isActive}
                      onClick={() => {
                        closeAll();
                        handleClickActive('1W');
                        setTime('1W');
                      }}
                    >
                      <Label theme={theme}>1W</Label>
                    </OptionWeek>
                    <OptionMonth
                      isActive={isActive}
                      onClick={() => {
                        onClose();
                        handleClickActive('1M');
                        setTime('1M');
                      }}
                    >
                      <Label theme={theme}>1M</Label>
                    </OptionMonth>
                  </>
                )}
                {!isMobile && !location.pathname.includes('webview') ? (
                  <>
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart?.chart().executeActionById('insertIndicator');
                      }}
                    >
                      <LineChartUpIcon className="iconLineChartUpIcon" />
                    </Option>
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart?.chart().executeActionById('chartProperties');
                      }}
                    >
                      <SettingFilledIcon className="iconSettingFilledIcon" />
                    </Option>
                    <Option
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredList(!isShowFavoredList);
                      }}
                    >
                      <IconBox
                        tradingViewChart={tradingViewChart}
                        isShowFavoredList={isShowFavoredList}
                        isChooseOption={isChooseOption}
                      />
                      {isShowFavoredList && (
                        <DropDownList isChooseOption={isChooseOption} setIsChooseOption={setIsChooseOption} />
                      )}
                    </Option>
                    {/* <Option>
                      <RoundZoomOutIcon
                        className="iconRoundZoomOutIcon"
                        onClick={() => {
                          onClose();
                          handleClickFullscreen();
                        }}
                        onKeyDown={handleClickExitFullscreen}
                      />
                    </Option> */}
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart?.save((res) => {
                          window.localStorage.setItem('trandingview.object.futureexchange', JSON.stringify(res));
                        });

                        window.localStorage.setItem('tradingview.time', time);
                      }}
                    >
                      <EnctypeArrowUpIcon className="iconEnctypeArrowUpIcon" />
                    </Option>
                    <Option
                      onClick={() => {
                        onClose();
                        if (window.localStorage.getItem('trandingview.object.futureexchange')) {
                          tradingViewChart?.load(
                            JSON.parse(window.localStorage.getItem('trandingview.object.futureexchange') || ''),
                          );
                        }

                        setTime(window.localStorage.getItem('tradingview.time') || '60');
                      }}
                    >
                      <EnctypeArrowDownIcon className="iconEnctypeArrowDownIcon" />
                    </Option>
                    <ChartType>
                      <ChartTypeTradingView
                        onClick={() => {
                          setChartActive(TRADING_VIEW_CHART);
                          onClose();
                        }}
                        chartActive={chartActive}
                      >
                        {t('tradingView.trading_view')}
                      </ChartTypeTradingView>
                      <ChartTypeDepth
                        onClick={() => {
                          setChartActive(DEPTH_CHART);
                          onClose();
                        }}
                        chartActive={chartActive}
                      >
                        {t('tradingView.depth')}
                      </ChartTypeDepth>
                      <img
                        style={{ cursor: 'pointer' }}
                        src={
                          theme === THEME_MODE.LIGHT
                            ? !isFullscreen
                              ? iconZoomIn
                              : iconZoomOut
                            : !isFullscreen
                            ? iconZoomInDark
                            : iconZoomOutDark
                        }
                        onClick={() => {
                          setIsFullscreen(!isFullscreen);
                          if (!isFullscreen) {
                            onClose();
                            handleClickFullscreen();
                          } else {
                            document.exitFullscreen();
                          }
                        }}
                        onKeyDown={handleClickExitFullscreen}
                      />
                    </ChartType>
                  </>
                ) : (
                  <>
                    {!location.pathname.includes('webview') && (
                      <OptionDot onClick={handleClickShowExpand}>
                        <IconDot />
                        {isShowMoreOption && (
                          <MoreOptionBox theme={theme}>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.chart().executeActionById('insertIndicator');
                              }}
                            >
                              <LineChartUpIcon />
                            </Option>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.chart().executeActionById('chartProperties');
                              }}
                            >
                              <SettingFilledIcon />
                            </Option>
                            <Option
                              onClick={() => {
                                closeAll();
                                setIsShowFavoredList(!isShowFavoredList);
                              }}
                            >
                              <IconBox
                                tradingViewChart={tradingViewChart}
                                isShowFavoredList={isShowFavoredList}
                                isChooseOption={isChooseOption}
                              />
                              {isShowFavoredList && (
                                <DropDownList isChooseOption={isChooseOption} setIsChooseOption={setIsChooseOption} />
                              )}
                            </Option>
                            {/* <Option>
                              <RoundZoomOutIcon
                                onClick={() => {
                                  onClose();
                                  handleClickFullscreen();
                                }}
                                onKeyDown={handleClickExitFullscreen}
                              />
                            </Option> */}
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.saveChartToServer();
                              }}
                            >
                              <EnctypeArrowUpIcon />
                            </Option>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.showLoadChartDialog();
                              }}
                            >
                              <EnctypeArrowDownIcon />
                            </Option>
                            <ChartType width={isMobile}>
                              <ChartTypeTradingView
                                onClick={() => {
                                  setChartActive(TRADING_VIEW_CHART);
                                  onClose();
                                }}
                                chartActive={chartActive}
                              >
                                Trading View
                              </ChartTypeTradingView>
                              <ChartTypeDepth
                                onClick={() => {
                                  setChartActive(DEPTH_CHART);
                                  onClose();
                                }}
                                chartActive={chartActive}
                              >
                                Depth
                              </ChartTypeDepth>
                              <img
                                style={{ cursor: 'pointer' }}
                                src={
                                  theme === THEME_MODE.LIGHT
                                    ? !isFullscreen
                                      ? iconZoomIn
                                      : iconZoomOut
                                    : !isFullscreen
                                    ? iconZoomInDark
                                    : iconZoomOutDark
                                }
                                onClick={() => {
                                  setIsFullscreen(!isFullscreen);
                                  if (!isFullscreen) {
                                    onClose();
                                    handleClickFullscreen();
                                  } else {
                                    document.exitFullscreen();
                                  }
                                }}
                                onKeyDown={handleClickExitFullscreen}
                              />
                            </ChartType>
                          </MoreOptionBox>
                        )}
                      </OptionDot>
                    )}
                  </>
                )}
              </TimeOption>
            </>
          )}
          {chartActive === DEPTH_CHART && (
            <TimeOption>
              {location.pathname.includes('webview') ? (
                <OptionsWebview>
                  {TIME_WEBVIEW.map((item, index: number) => (
                    <>
                      <TimeValue
                        onClick={() => {
                          setChartActive(TRADING_VIEW_CHART);
                          setTime(item.value);
                        }}
                        key={index}
                      >
                        {item.label}
                      </TimeValue>
                      <Line />
                    </>
                  ))}
                  <DepthOption
                    onClick={() => {
                      setChartActive(DEPTH_CHART);
                    }}
                    chartActive={chartActive}
                  >
                    {t('tradingView.depth')}
                  </DepthOption>
                </OptionsWebview>
              ) : (
                <ChartType>
                  <ChartTypeTradingView
                    onClick={() => {
                      setChartActive(TRADING_VIEW_CHART);
                      onClose();
                    }}
                    chartActive={chartActive}
                  >
                    {t('tradingView.trading_view')}
                  </ChartTypeTradingView>
                  <ChartTypeDepth
                    onClick={() => {
                      setChartActive(DEPTH_CHART);
                      onClose();
                    }}
                    chartActive={chartActive}
                  >
                    {t('tradingView.depth')}
                  </ChartTypeDepth>
                  <img
                    style={{ cursor: 'pointer' }}
                    src={
                      theme === THEME_MODE.LIGHT
                        ? !isFullscreen
                          ? iconZoomIn
                          : iconZoomOut
                        : !isFullscreen
                        ? iconZoomInDark
                        : iconZoomOutDark
                    }
                    onClick={() => {
                      setIsFullscreen(!isFullscreen);
                      if (!isFullscreen) {
                        onClose();
                        handleClickFullscreen();
                      } else {
                        document.exitFullscreen();
                      }
                    }}
                    onKeyDown={handleClickExitFullscreen}
                  />
                </ChartType>
              )}
            </TimeOption>
          )}
        </ChartOption>
        <ChartContent>
          {chartActive === TRADING_VIEW_CHART && (
            <TradingView
              valueInterval={time}
              setTradingViewChartParent={setTradingViewChartParent}
              containerId={'trading-view-chart'}
              clientId="vcc"
            />
          )}
          {chartActive === DEPTH_CHART && <HighChart />}
        </ChartContent>
      </FullScreen>
    </ChartWrapper>
  );
};

export default ChartIndex;
