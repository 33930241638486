import classnames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import Loading from 'src/components/Loading';
import styles from 'src/features/PositionAndHistory/styles/Order.module.scss';
import { formatOrderEnum, formatPriceRoundDown } from 'src/helpers/numberFormatter';
import { formatTime } from 'src/helpers/stringFormatter';
import { IOrder, OrderStatus } from 'src/interfaces/order';
import { cancelOrder, cancelAllOrder, getOpenOrder } from 'src/services/order';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { CancelOrderDialog } from './dialog/CancelOrderDialog/index';
import { ViewOrderDialog } from './dialog/ViewOrderDialog/index';
import DropdownMenu from 'src/components/cores/Dropdown/Dropdown';
import BigNumber from 'bignumber.js';
import { Pagination } from '@mui/material';
import { OrderSide, OrderType } from './constant';
import { RECORDS_PER_PAGE, TypeTrade } from 'src/constants/common.constants';
import { handleDirect } from './PositionRecord';
import { getCookieStorage } from 'src/helpers/storage';
import { arrowDown, reduceIconDark, iconInfoLight, iconInfoDark, noData, noDataDark } from 'src/assets/icon';
import { THEME_MODE } from 'src/interfaces/theme';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import TooltipText from 'src/components/cores/TooltipText';
import { Box } from '@material-ui/core';
import { formatOrderText, getContractType, getSymbolName } from './helper';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'src/hooks/useScreen';

export enum PriceTypeValue {
  last = 'LAST',
  mark = 'ORACLE',
}

export enum CancelType {
  all = 'ALL',
  limit = 'LIMIT',
  stopLimit = 'STOP',
}

export enum CancelLabel {
  all = 'order.all',
  limit = 'order.limit',
  stopLimit = 'order.stop',
}

export const CANCEL_ACTION = [
  { value: CancelType.all, label: CancelLabel.all },
  { value: CancelType.limit, label: CancelLabel.limit },
  { value: CancelType.stopLimit, label: CancelLabel.stopLimit },
];

const cx = classnames.bind(styles);

const OpenOrder = (props: any) => {
  const { hideOtherPairs } = props;
  const dispatch = useAppDispatch();

  const orders = useAppSelector((state) => state.order.orders);
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector((state) => state.instrument.currentInstrument);
  const account = useAppSelector((state) => state.account.account);
  const currentPage = useAppSelector((state) => state.order.currentPage);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);

  const totalPage = useAppSelector((state) => state.order.totalPage);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);

  const [loading, setLoading] = useState(false);
  const loadingOrder = useAppSelector((state) => state.order.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTradingRules = useAppSelector((state) => state.masterdataFuture.loading);

  const [openCancelAllDialog, setOpenCancelAllDialog] = useState<boolean>(false);
  const [orderSelectedToView, setOrderSelectedToView] = useState<IOrder | null>();
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IOrder[]>([]);
  const [cancelType, setCancelType] = useState<string>('');
  const token = getCookieStorage('access_token');
  const { t } = useTranslation('common');
  const isExpired = getCookieStorage('isExpired');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined';
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const size = useWindowSize();

  const fetchData = async (page: number) => {
    const params = { page: page, size: RECORDS_PER_PAGE };
    if (hideOtherPairs) {
      const payload = { symbol: currentInstrument.symbol, contractType };
      dispatch(getOpenOrder({ params, payload }));
    } else {
      const payload = { contractType };
      dispatch(getOpenOrder({ params, payload }));
    }
  };

  useEffect(() => {
    setLoading(loadingInstrument || loadingTradingRules);
  }, [loadingInstrument, loadingTradingRules]);

  useEffect(() => {
    if (currentPage && isAuthenticated) {
      // if (changeCheckboxRef.current !== hideOtherPairs || changeInstrumentRef.current !== currentInstrument.symbol) {
      //   fetchData(1);
      // }
      if (changeCheckboxRef.current === hideOtherPairs && changeInstrumentRef.current === currentInstrument.symbol) {
        fetchData(currentPage);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairs, currentInstrument]);

  useEffect(() => {
    if (orders) {
      setRows(orders);
    }
  }, [orders, account[0]?.id]);

  useEffect(() => {
    if (orderSelectedToView) {
      const findOrder = allOrders.find((item) => Number(item?.id) === Number(orderSelectedToView?.id));
      if (findOrder) {
        const isPartiallyFilled =
          Number(findOrder.remaining) < Number(findOrder.quantity) && Number(findOrder.remaining) > 0;
        if (isPartiallyFilled) {
          setOrderSelectedToView(findOrder);
        }
      } else {
        setOpenViewDialog(false);
      }
    }
  }, [allOrders]);

  const renderPrice = (order: IOrder, currentPrice: string) => {
    const curTradingRule = tradingRulesCache.filter((item) => item.symbol === order?.symbol);
    const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return <TooltipNumber characters={11} decimal={maxFiguresForPrice} defaultValue={currentPrice} />;
  };

  const renderQuantity = (order: IOrder) => {
    const currentInstrument = instruments.find((instrument) => instrument?.symbol === order?.symbol);
    const curTradingRule = tradingRulesCache.filter((item) => item.symbol === order?.symbol);
    const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={7}
        decimal={isCoinM ? 0 : maxFiguresForSize}
        symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
        defaultValue={order?.quantity}
      />
    );
  };

  const renderFilledQuantity = (order: IOrder) => {
    const fillQuantity = new BigNumber(order.quantity ?? '0').minus(order.remaining ?? '0');
    const currentInstrument = instruments.find((instrument) => instrument?.symbol === order?.symbol);
    const curTradingRule = tradingRulesCache.filter((item) => item.symbol === order?.symbol);
    const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={7}
        decimal={isCoinM ? 0 : maxFiguresForSize}
        symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
        defaultValue={fillQuantity}
      />
    );
  };

  const renderTriggerConditions = (order: IOrder) => {
    if (!order.stopCondition) return '--';
    if (order.tpSLType === OrderType.stopLimit && order.status === OrderStatus.ACTIVE) return '--';
    const currentInstrument = instruments.find((instrument) => instrument.symbol === order.symbol);
    const tickSize = Number(currentInstrument?.tickSize) || '0.01';
    const precision = -Math.ceil(Math.log10(Number(tickSize)));

    const isTrailingStop = order.tpSLType === OrderType.trailingStop;
    const text = `${t('order.activation_price')}: ${formatPriceRoundDown(order?.activationPrice, precision)}. ${t(
      'order.trigger_type',
    )}: ${order?.trigger === PriceTypeValue.last ? t('order.last_price') : t('order.mark_price')}, ${t(
      'order.callback_rate',
    )}: ${order?.callbackRate ?? '0'}%.`;

    if (order.trigger === PriceTypeValue.last) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t('order.activation_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.activationPrice} characters={7} decimal={precision} />
            &nbsp;
            <TooltipText text={text}>
              <img src={theme === THEME_MODE.LIGHT ? iconInfoLight : iconInfoDark} alt="" className={cx('tif-icon')} />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t('order.last_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.tpSLPrice} characters={7} decimal={precision} />
          </Box>
        );
      }
    } else if (order.trigger === PriceTypeValue.mark) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t('order.activation_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.activationPrice} characters={7} decimal={precision} />
            &nbsp;
            <TooltipText text={text}>
              <img src={theme === THEME_MODE.LIGHT ? iconInfoLight : iconInfoDark} alt="" className={cx('tif-icon')} />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t('order.mark_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.tpSLPrice} characters={7} decimal={precision} />
          </Box>
        );
      }
    }
  };

  const renderTime = (order: IOrder) => {
    if (
      order.tpSLType === OrderType.takeProfitMarket ||
      (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
    ) {
      return formatTime(order.updatedAt);
    }

    return formatTime(order.createdAt);
  };

  const renderTypeOrder = (order: IOrder) => {
    if (order.tpSLType === OrderType.stopLimit && order.status === OrderStatus.ACTIVE) {
      return t(formatOrderText(order.type));
    }

    if (order.tpSLType === OrderType.takeProfitMarket) {
      return t('order.take_profit');
    }
    if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
      return t('order.stop_loss');
    }

    return t(formatOrderText(order.tpSLType || order.type));
  };

  const renderAmount = (order: IOrder) => {
    if (!order.parentOrderId) {
      if (
        order.tpSLType === OrderType.takeProfitMarket ||
        (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
      ) {
        return t('order.close_position');
      }
    }

    return renderQuantity(order);
  };

  const renderReduceOnly = (order: IOrder) => {
    return order.isReduceOnly ? t('order.yes') : t('order.no');
  };

  const handleViewOrder = (order: IOrder) => {
    let isShowBtnView = false;
    if (!order.parentOrderId) {
      if (order.isTpSlTriggered === false) {
        if (order.takeProfitOrderId || order.stopLossOrderId) isShowBtnView = true;
      }
    } else {
      isShowBtnView = true;
    }
    return isShowBtnView;
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const handleClickDetail = (data: IOrder) => {
    setOrderSelectedToView(data);
    setOpenViewDialog(true);
  };

  const handleOpenCancelAllDialog = () => {
    setOpenCancelAllDialog(true);
  };

  const handleCloseCancelAllDialog = () => {
    setOpenCancelAllDialog(false);
  };

  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const handleChangeCancelType = (value: any) => {
    if (!rows.length) return;
    setCancelType(value);
    handleOpenCancelAllDialog();
  };

  const handleCancelAllOrder = async () => {
    handleCloseCancelAllDialog();
    await dispatch(cancelAllOrder({ type: cancelType, contractType }));
  };

  const handleCancelOrder = async (orderSelectedToCancel: IOrder) => {
    await dispatch(cancelOrder(orderSelectedToCancel?.id));
  };

  return (
    <div className={'height-100'}>
      <div className={cx('tbl_data')}>
        {!isAuthenticated && (
          <div className={cx('no-orders')}>
            <div className={cx('warning-table')}>
              <strong onClick={() => handleDirect('login')}>{t('order.login')}</strong> {t('order.or')}{' '}
              <strong onClick={() => handleDirect('signup')}>{t('order.signup')}</strong> {t('order.to_start_trading')}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={'height-100'} style={{ position: 'relative' }}>
            <div className={cx('table-content')} style={{ minHeight: '450px' }}>
              <div
                className={cx('no-data')}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '25%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {!loading && !rows.length && (
                  <>
                    {theme === THEME_MODE.LIGHT ? (
                      <img src={noData} width={74} height={85} />
                    ) : (
                      <img src={noDataDark} width={74} height={85} />
                    )}
                    <div className={cx('no-data-desc')}>{t('order.no_open_orders')}</div>
                  </>
                )}

                {loading && <Loading />}
              </div>

              <table className={cx('dex_table', 'text-table-xs')}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px', width: '9%' }}>{t('order.time')}</th>
                    <th style={{ minWidth: '90px', width: '7%' }}>{t('order.symbol')}</th>
                    <th style={{ minWidth: '90px', width: '7%' }}>{t('order.type')}</th>
                    <th style={{ minWidth: '65px', width: '4%' }}>{t('order.side')}</th>
                    <th style={{ minWidth: '90px', width: '7%' }}>{t('order.price')}</th>
                    <th style={{ minWidth: '120px', width: '9%' }}>{t('order.amount')}</th>
                    <th style={{ minWidth: '120px', width: '9%' }}>{t('order.filled')}</th>
                    <th style={{ minWidth: '220px', width: '16%' }}>{t('order.trigger_condition')}</th>
                    <th style={{ minWidth: '90px', width: '7%' }}>{t('order.tpsl')}</th>
                    <th style={{ minWidth: '90px', width: '7%' }}>{t('order.reduceOnly')}</th>
                    <th style={{ minWidth: '120px', width: '9%' }}>{t('order.postOnly')}</th>
                    <th style={{ minWidth: '78px', width: '6%', paddingRight: '0px' }}>
                      <div
                        className={cx('type-cancel-order')}
                        onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                          setRefEml(event.currentTarget);
                        }}
                      >
                        <span>{t('order.cancel_all')}</span>
                        <img
                          className={cx('icon-down', Boolean(refElm) && 'rotate')}
                          src={theme === THEME_MODE.LIGHT ? arrowDown : reduceIconDark}
                        />
                      </div>
                      <DropdownMenu
                        open={Boolean(refElm)}
                        options={CANCEL_ACTION}
                        handleClose={() => setRefEml(null)}
                        refElm={refElm}
                        handleChooseOption={handleChangeCancelType}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={cx('dex_table__scrollbar', 'overflow-tbody')}
                  style={{ maxHeight: 210, verticalAlign: 'top' }}
                >
                  {loading && <div style={{ height: 230 }}></div>}
                  {!loading && !rows.length && <div style={{ height: 230 }}></div>}

                  {!loading && !!rows.length && (
                    <>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className={cx('text-value')}>{renderTime(row)}</td>
                          <td
                            className={cx('symbol-order')}
                            onClick={() => {
                              if (currentInstrument.symbol === row.symbol) return;
                              window.location.replace(`/futures/${row?.contractType}/${row.symbol}`);
                            }}
                          >
                            <div className={cx('text-value')}>{getSymbolName(row.symbol ?? '')}</div>
                            <div className={cx('text-perpetual')}>{t('order.perpetual')}</div>
                          </td>
                          <td className={cx('text-value')}>{renderTypeOrder(row)}</td>
                          <td className={cx(row.side === OrderSide.buy ? 'color-buy' : 'color-sell')}>
                            {t(formatOrderText(row.side))}
                          </td>
                          <td className={cx('text-value')}>
                            {row.type === OrderType.limit ? renderPrice(row, row?.price ?? '0') : '--'}
                          </td>
                          <td className={cx('text-value')}>{renderAmount(row)}</td>
                          <td className={cx('text-value')}>{renderFilledQuantity(row)}</td>
                          <td className={cx('text-value')}>
                            {Number(row.tpSLPrice) || Number(row.activationPrice) ? renderTriggerConditions(row) : '--'}
                          </td>
                          <td className={cx('text-value')}>
                            {handleViewOrder(row) ? (
                              <span
                                className={cx('btn-view')}
                                onClick={(e) => {
                                  handleClickDetail(row);
                                  e.stopPropagation();
                                }}
                              >
                                {t('order.view')}
                              </span>
                            ) : (
                              '--'
                            )}
                          </td>
                          <td className={cx('text-value')}>{renderReduceOnly(row)}</td>
                          <td className={cx('text-value')}>{row?.isPostOnly ? t('order.yes') : t('order.no')}</td>
                          <td>
                            <button
                              className={cx('text-value', 'btn-underline')}
                              onClick={(e) => {
                                handleCancelOrder(row);
                                e.stopPropagation();
                              }}
                            >
                              {t('order.cancel')}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {!loading && totalPage > 1 && (
                <div className={cx('pagination')}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, page) => {
                      fetchData(page);
                    }}
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    className={cx('pagination-custom')}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'var(--color-value-1) !important',
                      },
                      '& .Mui-selected': {
                        backgroundColor: 'var(--color-background-pagination) !important',
                        border: '1px solid var(--color-outline-pagination) !important',
                        color: 'var(--color-text-pagination) !important',
                        borderRadius: '5px !important',
                      },
                      '& .MuiPagination-ul': {
                        flexWrap: 'nowrap',
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {orders.length > 0 && (
          <CancelOrderDialog
            cancelType={cancelType}
            handleCloseDialog={handleCloseCancelAllDialog}
            handleConfirm={handleCancelAllOrder}
            openDialog={openCancelAllDialog}
          />
        )}
        {orderSelectedToView && (
          <ViewOrderDialog
            data={orderSelectedToView}
            handleCloseDialog={handleCloseViewDialog}
            openDialog={openViewDialog}
          />
        )}
      </div>
    </div>
  );
};

export default OpenOrder;
