/* eslint-disable max-len */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import marketTradeReducer from 'src/features/OrderbookTrade/redux/MarketTrade.slice';
import orderbookReducer from 'src/features/OrderbookTrade/redux/Orderbook.slice';
import accountReducer from 'src/services/account';
import fundingReducer from 'src/services/funding';
import fundingRateReducer from 'src/services/fundingRate';
import instrumentReducer from 'src/services/instrument';
import orderReducer from 'src/services/order';
import positionReducer from 'src/services/position';
import themeReducer from 'src/services/theme';
import tickerReducer from 'src/services/ticker';
import tradeReducer from 'src/services/trade';
import userReducer from 'src/services/user';
import withdrawReducer from 'src/services/withdraw';
import accountOverviewReducer from 'src/services/account/orders';
import authReducer from 'src/store/auth';
import transactionHistoryReducer from 'src/services/transaction-history';
import generalReducer from 'src/services/general';
import orderHistoryReducer from 'src/services/order-history';
import masterdataReducer from 'src/services/masterdata';
import masterdataFutureReducer from 'src/services/masterdataFuture';
import typeTradeReducer from 'src/services/typeTrade';

const store = configureStore({
  reducer: {
    instrument: instrumentReducer,
    account: accountReducer,
    position: positionReducer,
    auth: authReducer,
    orderbook: orderbookReducer,
    marketTrade: marketTradeReducer,
    ticker: tickerReducer,
    order: orderReducer,
    transactionHistory: transactionHistoryReducer,
    tradeHistory: tradeReducer,
    funding: fundingReducer,
    theme: themeReducer,
    user: userReducer,
    fundingRate: fundingRateReducer,
    withdraw: withdrawReducer,
    accountOverview: accountOverviewReducer,
    general: generalReducer,
    orderHistory: orderHistoryReducer,
    masterdata: masterdataReducer,
    masterdataFuture: masterdataFutureReducer,
    typeTrade: typeTradeReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
//Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type RootStore = typeof store;
export default store;
