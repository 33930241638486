/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosRequestConfig } from 'axios';
import { TIMEOUT } from 'src/constants/common.constants';
import { serializeMessage } from 'src/helpers/authHelper';
import { getCookieStorage, removeAllCookieStorage, setAllCookieStorage } from 'src/helpers/storage';
import { noti } from 'src/hooks/useNoti';
import { errorMessage } from 'src/messages/errorMessages';
import { setUser } from 'src/services/user';
import { BaseSocket } from 'src/socket/BaseSocket';
import store from 'src/store/store';

const axiosInstanceSpot = axios.create({
  baseURL: process.env.REACT_APP_API_SPOT,
  timeout: TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstanceSpot.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = getCookieStorage('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstanceSpot.interceptors.response.use(
  async (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (error) => {
    // if (!error.response) {
    //   noti.error(errorMessage.AUTHEN);
    //   return Promise.reject(error);
    // }
    switch (error.response?.status) {
      case 401:
        noti.error(errorMessage.SERVER_INTERNAL_ERROR);
        break;

      case 500:
        noti.error(errorMessage.SERVER_INTERNAL_ERROR);
        break;

      case 400:
        break;

      case 403:
        noti.error(errorMessage.PERMISSION_DENIED);
        break;

      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default axiosInstanceSpot;
