import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';

export type ITradingRule = {
  id: number;
  instrumentId: number;
  minTradeAmount: string;
  minOrderAmount: string;
  maxOrderAmount: string;
  minPrice: string;
  maxPrice?: string;
  limitOrderPrice: string;
  floorRatio: string;
  maxMarketOrder: string;
  limitOrderAmount: string;
  numberOpenOrders: string;
  priceProtectionThreshold: string;
  liqClearanceFee: string;
  minNotional: string;
  maxNotinal?: string;
  minPriceMovement?: string;
  marketOrderPrice: string;
  isReduceOnly: boolean;
  positionsNotional: string;
  ratioOfPostion: string;
  liqMarkPrice: string;
  maxLeverage: string;
  createdAt: string;
  updatedAt: string;
  multiplier?: string;
};

export interface ILeverageMargin {
  instrumentId: string;
  maintenanceAmount: string;
  maintenanceMarginRate: number;
  max: string;
  min: string;
  maxLeverage: number;
  tier: number;
}

export type ICoinInfo = {
  id: number;
  fullName: string;
  baseId: string;
  symbol: string;
  rank: string;
  marketCap: string;
  cirSupply: string;
  maxSupply: string;
  totalSupply: string;
  issueDate: null;
  issuePrice: null;
  explorer: string;
  createdAt: string;
  updatedAt: string;
  coin_image: string;
};

export type IFundingRateHistory = {
  id: number;
  time: string;
  fundingQuantity: string;
  fundingRate: string;
  fundingInterval: string;
  amount: string;
  symbol: string;
};

export type IFundingRateHistoryChart = {
  id: number;
  time: string;
  fundingQuantity: string;
  fundingRate: string;
  fundingInterval: string;
  amount: string;
  symbol: string;
};

const initialState = {
  tradingRule: <ITradingRule | undefined>{},
  loading: false,
  leverageMargin: <ILeverageMargin[]>[],
  coinInfo: <ICoinInfo | undefined>{},
  fundingRateHistory: <IFundingRateHistory[]>[],
  fundingRateHistoryChart: <IFundingRateHistoryChart[]>[],
};

export const getTradingRule = createAsyncThunk('getTradingRule', async (symbol: string) => {
  const res = axiosInstance
    .get(`/trading-rules/symbol`, {
      params: {
        symbol: symbol,
      },
    })
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getLeverageMargin = createAsyncThunk('getLeverageMargin', async (symbol: string) => {
  const res = axiosInstance
    .get(`/leverage-margin`, {
      params: {
        symbol: symbol,
      },
    })
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getCoinInfo = createAsyncThunk('getCoinInfo', async (symbol: string) => {
  const res = axiosInstance
    .get(`/coin-info`, {
      params: {
        symbol: symbol,
      },
    })
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const getFundingRateHistory = createAsyncThunk('getFundingRateHistory', async (params: { symbol?: string }) => {
  return await axiosInstance.get(`/funding/history`, { params });
});

export const accountSlice = createSlice({
  name: 'general-information',
  initialState,
  reducers: {
    setTradingRule: (state, action: PayloadAction<ITradingRule | undefined>) => {
      state.tradingRule = action.payload;
    },
  },
  extraReducers: {
    [getTradingRule.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getTradingRule.rejected.toString()]: (state) => {
      state.loading = true;
    },
    [getTradingRule.fulfilled.toString()]: (state, action) => {
      state.tradingRule = action.payload.data;
      state.loading = false;
    },

    [getCoinInfo.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getCoinInfo.rejected.toString()]: (state) => {
      state.loading = true;
    },
    [getCoinInfo.fulfilled.toString()]: (state, action) => {
      state.coinInfo = action.payload.data;
      state.loading = false;
    },

    [getLeverageMargin.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getLeverageMargin.rejected.toString()]: (state) => {
      state.loading = true;
    },
    [getLeverageMargin.fulfilled.toString()]: (state, action) => {
      state.leverageMargin = action.payload.data;
      state.loading = false;
    },

    [getFundingRateHistory.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getFundingRateHistory.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getFundingRateHistory.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.fundingRateHistory = action.payload.data;
    },
  },
});

export const { setTradingRule } = accountSlice.actions;

const { reducer: generalReducer } = accountSlice;

export default generalReducer;
