import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import { iconCloseCommon, iconCloseCommonDark, iconTif, tifIconDark2 } from 'src/assets/icon';
import classnames from 'classnames/bind';
import styles from './OrderConfirmModal.module.scss';
import { OrderSide, OrderType, WorkingType } from '../../Constant';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '8px',
    },
    '& .MuiDialogActions-root': {
      fontFamily: 'inherit',
    },
    '& .MuiTypography-root': {
      fontFamily: 'inherit',
    },
    '& .MuiDialogTitle-root': {
      fontFamily: 'inherit',
    },
  },
});

type OrderConfirmProps = {
  open: boolean;
  side: OrderSide;
  orderType: string;
  stopPrice?: string;
  price?: string;
  quantity?: string;
  callBackRate?: string;
  activationPrice?: string;
  takeProfit?: string;
  takeProfitType?: string;
  stopLoss?: string;
  stopLossType?: string;
  isCoinM: boolean;
  handleClose: () => void;
  createOrder: () => void;
};

export const OrderConfirmModal = ({
  open,
  side,
  orderType,
  stopPrice,
  price,
  quantity,
  callBackRate,
  activationPrice,
  takeProfit,
  stopLoss,
  takeProfitType,
  stopLossType,
  isCoinM,
  handleClose,
  createOrder,
}: OrderConfirmProps) => {
  const instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const classes = useStyles();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const { t } = useTranslation('common');

  const formatTPSL = (type: string | undefined, tpslType: string, side: string, value: string) => {
    const trigger = type === WorkingType[0].value ? t('order.mark_price') : t('order.last_price');
    let condition = '';
    if (tpslType === 'takeProfit') {
      condition = side === 'BUY' ? '>=' : '<=';
    } else condition = side === 'BUY' ? '<=' : '>=';

    return trigger + ' ' + condition + ' ' + Number(value).toLocaleString();
  };

  return (
    <Dialog maxWidth={'md'} open={open} onClose={handleClose} className={isMobile ? classes.root : ''}>
      {/*title*/}
      <DialogTitle className={cx('title-prefer')}>
        <Box>
          <Typography component={'div'} style={{ position: 'absolute', top: '15px', right: '15px' }}>
            <IconButton size={'small'} className={cx('hidden')} onClick={handleClose}>
              <img src={theme === THEME_MODE.LIGHT ? iconCloseCommon : iconCloseCommonDark} alt="close" />
            </IconButton>
          </Typography>

          <div className={cx('title-header')}>{t('order.order_confirmation')}</div>
        </Box>
      </DialogTitle>

      {/*contents*/}
      <DialogContent className={cx('content')}>
        {/* Tab here */}
        <div className={cx('container')}>
          <div className={cx('flex')}>
            <div className={cx('symbol')}>
              {instrument.symbol} {t('order.perpetual')}
            </div>
            <div className={side === 'BUY' ? cx('buy') : cx('sell')}>
              {side === 'BUY' ? t('order.buy_long') : t('order.sell_short')}
            </div>
          </div>
          {(orderType === OrderType.stopLimit || orderType === OrderType.stopMarket) && (
            <div className={cx('flex')}>
              <div className={cx('field')}>{t('order.stop_price')}</div>
              <div className={cx('value')}>
                {Number(stopPrice).toLocaleString()} {instrument.quoteCurrency}
              </div>
            </div>
          )}

          {orderType !== OrderType.trailingStop && (
            <div className={cx('flex')}>
              <div className={cx('field')}>{t('order.price')}</div>
              {orderType === OrderType.market || orderType === OrderType.stopMarket ? (
                <div className={cx('value')}>{t('order.market')}</div>
              ) : (
                <div className={cx('value')}>
                  {Number(price).toLocaleString()} {instrument.quoteCurrency}
                </div>
              )}
            </div>
          )}

          {orderType === OrderType.trailingStop && (
            <>
              <div className={cx('flex')}>
                <div className={cx('field')}>{t('order.callback_rate')}</div>
                <div className={cx('value')}>{callBackRate} %</div>
              </div>
              <div className={cx('flex')}>
                <div className={cx('field')}>{t('order.activation_price')}</div>
                <div className={cx('value')}>
                  {Number(activationPrice).toLocaleString()} {instrument.quoteCurrency}
                </div>
              </div>
            </>
          )}
          <div className={cx('flex')}>
            <div className={cx('field')}>{t('order.order_quantity')}</div>
            <div className={cx('value')}>
              {Number(quantity).toLocaleString()} {isCoinM ? 'Cont' : instrument.rootSymbol}
            </div>
          </div>

          {(takeProfit || stopLoss) && <div className={cx('br')} />}
          {takeProfit && (
            <div className={cx('flex')}>
              <div className={cx('field')}>{t('order.take_profit')}</div>
              <div className={cx('value')}>
                {formatTPSL(takeProfitType, 'takeProfit', side, takeProfit)} {instrument.quoteCurrency}
              </div>
            </div>
          )}
          {stopLoss && (
            <div className={cx('flex')}>
              <div className={cx('field')}>{t('order.stop_loss')}</div>
              <div className={cx('value')}>
                {formatTPSL(stopLossType, 'stopLoss', side, stopLoss)} {instrument.quoteCurrency}
              </div>
            </div>
          )}
        </div>
        <div className={cx('info')}>
          <img src={theme === THEME_MODE.LIGHT ? iconTif : tifIconDark2} alt="" className={cx('tif-icon')} />
          <span>{t('order.order_noti')}</span>
        </div>

        <div className={cx('bottom')}>
          <div className={cx('button', 'button-close')} onClick={handleClose}>
            {t('order.close')}
          </div>
          <div
            className={side === 'BUY' ? cx('button', 'buy-button') : cx('button', 'sell-button')}
            onClick={createOrder}
          >
            {side === 'BUY' ? `${t('order.buy_long')}` : `${t('order.sell_short')}`}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
