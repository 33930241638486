import classNames from 'classnames/bind';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';
import style from 'src/features/TradingViewChart/styles/HightCharts.module.scss';
import { IOrderbook } from 'src/interfaces/orderbook';
import { THEME_MODE } from 'src/interfaces/theme';
import { useAppSelector } from 'src/store/hooks';

interface Props {
  width?: number;
  id?: string;
  background?: string;
  height?: number;
}

const cx = classNames.bind(style);

const hexToRgb = (hex: string): { r: number; b: number; g: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(/ /g, ''));

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const HighChart: React.FC<Props> = (props) => {
  const data: IOrderbook = useAppSelector((state) => state.orderbook.orderbook);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const ref = useRef<HTMLDivElement>(null);

  const [arrayBids, setArrayBids] = useState<number[][]>([[]]);
  const [arrayAsks, setArrayAsks] = useState<number[][]>([[]]);
  const [height, setHeight] = useState<number>(0);

  // section for useEffect
  useEffect(() => {
    Array.from(
      document.getElementsByClassName('highcharts-credits') as HTMLCollectionOf<HTMLElement>,
    )[0].style.visibility = 'hidden';
  }, []);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

  useEffect(() => {
    if (data.bids !== undefined) {
      arrayBids.length = 0;
      arrayAsks.length = 0;
      let bidAmount = 0;
      let askAmount = 0;

      const bids = data.bids.map((element: string[]) => {
        bidAmount += Number(element[1]);
        return [Number(element[0]), bidAmount];
      });
      setArrayBids(bids.reverse());

      const ask = data.asks.map((element: string[]) => {
        askAmount += Number(element[1]);
        return [Number(element[0]), askAmount];
      });

      setArrayAsks(ask);
    }
  }, [data]);

  const bidColor = hexToRgb(getComputedStyle(document.documentElement).getPropertyValue('--color-bg-bid'));

  const askColor = hexToRgb(getComputedStyle(document.documentElement).getPropertyValue('--color-bg-ask'));
  const options = {
    chart: {
      type: 'area',
      backgroundColor: theme === THEME_MODE.LIGHT ? '#fafafc' : '#1B1D24',
      height: height,
      width: props.width,
      animation: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      title: {
        text: '',
      },
      tickColor: '#8F90A6',
    },
    yAxis: [
      {
        visible: false,
      },
      {
        opposite: true,
        linkedTo: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        title: null,
        tickWidth: 1,
        tickLength: 5,
        tickPosition: 'outside',
        labels: {
          align: 'center',
          x: 8,
        },
        tickColor: '#8F90A6',
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      valueDecimals: 4,
    },
    series: [
      {
        name: 'Size',
        color: '#05A660',
        data: arrayBids,
        type: 'area',
        lineWidth: 2,
        step: 'center',
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, `rgba(${bidColor.r}, ${bidColor.g}, ${bidColor.b}, 1)`],
            [1, `rgba(${bidColor.r}, ${bidColor.g}, ${bidColor.b}, 0)`],
          ],
        },
      },
      {
        name: 'Size',
        color: '#E53535',
        data: arrayAsks,
        type: 'area',
        lineWidth: 2,
        step: 'center',
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, `rgba(${askColor.r}, ${askColor.g}, ${askColor.b}, 1)`],
            [1, `rgba(${askColor.r}, ${askColor.g}, ${askColor.b}, 0)`],
          ],
        },
      },
    ],
  };
  return (
    <div
      className={cx('high-chart-container')}
      id={'high-chart-container'}
      style={{ height: 'calc(100% - 16px)', width: '100%' }}
      ref={ref}
    >
      <HighchartsReact highcharts={Highcharts} options={options} allowChartUpdate={true} />
    </div>
  );
};
export default HighChart;
