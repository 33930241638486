import classnames from 'classnames/bind';
import React from 'react';
import ChartIndex from 'src/features/TradingViewChart/Components/Chart/ChartIndex';
import styles from 'src/features/Trading/Trading.module.scss';

const cx = classnames.bind(styles);

const Trading: React.FC = () => {
  return (
    <div className={cx('trading-wrapper')}>
      <ChartIndex />
    </div>
  );
};

export default Trading;
