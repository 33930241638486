export interface IOrder {
  id?: any;
  accountId?: number;
  symbol?: string;
  average?: string;
  tpSLPrice?: string;
  tpSLType?: string;
  isClosePositionOrder?: boolean;
  isTpSlTriggered?: boolean;
  parentOrderId?: number | string;
  linkedOrderId?: number | string;
  masterOrderId?: number;
  side?: string;
  instrumentSymbol?: string;
  type?: string;
  quantity?: string;
  price?: string;
  lockPrice?: string;
  orderValue?: string;
  remaining?: string;
  executedPrice?: string;
  stopType?: string;
  stopPrice?: string;
  stopCondition?: string;
  trigger?: string;
  timeInForce?: string;
  trailValue?: string;
  vertexPrice?: string;
  status?: string;
  isPostOnly?: boolean;
  isHidden?: boolean;
  isTriggered?: boolean;
  isReduceOnly?: boolean;
  isCanceling?: boolean;
  isMam?: boolean;
  pairType?: string;
  referenceId?: number;
  note?: string;
  createdAt?: string;
  updatedAt?: string;
  market?: string;
  amount?: string;
  takeProfit?: string;
  stopLoss?: string;
  takeProfitType?: string;
  stopLossType?: string;
  sizeType?: string;
  stopPriceType?: string;
  activationPriceType?: string;
  activationPrice?: string;
  callbackRate?: string;
  takeProfitOrderId?: number;
  stopLossOrderId?: number;
  isTpSlOrder?: boolean;
  contractType?: string;
  cost?: string;
  isShowToast?: boolean;
}

export interface IFilterOrder {
  active: boolean;
  page: number;
  size: number;
}

export enum OrderStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  UNTRIGGERED = 'UNTRIGGERED',
}
