import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { iconStarActive } from 'src/assets/icon';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

interface Props {
  title: string;
  takerFee: number;
  makerFee: number;
}

const FeeLevel: React.FC<Props> = ({ title, takerFee, makerFee }) => {
  const { t } = useTranslation('common');
  return (
    <div className={cx('wrapper')}>
      <div>
        <div className={cx('title')}>{title}</div>
        <div className={cx('content')}>
          <div className={cx('fee-header')}>
            <img src={iconStarActive} />
            <div>{t('order.your_fee_level')}</div>
          </div>
          <div className={cx('fee-detail')}>
            <span>{t('order.taker')}</span>
            <div>{takerFee}%</div>
          </div>
          <div className={cx('fee-detail')}>
            <span>{t('order.maker')}</span>
            <div>{makerFee}%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeLevel;
