export const listTab = [
  {
    label: 'order.position',
    value: 1,
  },
  {
    label: 'order.open_orders',
    value: 2,
  },
  {
    label: 'order.order_history',
  },
  {
    label: 'order.trade_history',
  },
  {
    label: 'order.transaction_history',
  },
  {
    label: 'order.asset',
  },
];

export enum SelectTimeValue {
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
}

export const listTimeSelect = [
  {
    label: 'order.one_day',
    value: SelectTimeValue.ONE_DAY,
  },
  {
    label: 'order.one_week',
    value: SelectTimeValue.ONE_WEEK,
  },
  {
    label: 'order.one_month',
    value: SelectTimeValue.ONE_MONTH,
  },
  {
    label: 'order.three_month',
    value: SelectTimeValue.THREE_MONTHS,
  },
];

export enum OrderSide {
  buy = 'BUY',
  sell = 'SELL',
}

export enum OrderType {
  market = 'MARKET',
  limit = 'LIMIT',
  stopMarket = 'STOP_MARKET',
  stopLimit = 'STOP_LIMIT',
  trailingStop = 'TRAILING_STOP',
  postOnly = 'POST_ONLY',
  takeProfitLimit = 'TAKE_PROFIT_LIMIT',
  takeProfitMarket = 'TAKE_PROFIT_MARKET',
}

export enum OrderStatusText {
  PENDING = 'Pending',
  ACTIVE = 'Partially_Filled',
  FILLED = 'Filled',
  CANCELED = 'Cancelled',
  UNTRIGGERED = 'Untriggered',
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  REALIZED_PNL = 'REALIZED_PNL',
}
