import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CalculatorMain } from './CalculatorMain';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';

const cx = classnames.bind(styles);

type CalculatorProps = {
  handleClose: () => void;
};

export const CalculatorOrder = ({ handleClose }: CalculatorProps) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <div className={cx('calculator-wrapper')}>
      {/*title*/}
      <DialogTitle className={cx('wrapper-title')}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Typography component={'div'}>
            <IconButton size={'small'} onClick={handleClose}>
              <img src={theme === THEME_MODE.LIGHT ? iconCloseCommon : iconCloseCommonDark} alt="close" />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>

      <CalculatorMain />
    </div>
  );
};
