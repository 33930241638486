import React from 'react';
import { IconRotate, IconWrapper } from 'src/features/TradingViewChart/Components/Chart/styles';
import {
  FilterChartIcon,
  ArrowDownChartIcon,
  IconBars,
  IconCandles,
  IconHollowCandles,
  IconHeikinAshi,
  IconLine,
  IconArea,
} from 'src/assets/icon';
import { IChartingLibraryWidget } from 'src/charting_library/charting_library.min';

type IconBoxProps = {
  isShowFavoredList: boolean;
  tradingViewChart?: IChartingLibraryWidget;
};

type IconRotateProps = {
  isChooseOption?: number;
  isShowFavoredList: boolean;
};

const IconBox: React.FC<IconBoxProps & IconRotateProps> = ({ isShowFavoredList, isChooseOption, tradingViewChart }) => {
  return (
    <IconWrapper>
      {isChooseOption === 0 ? (
        <FilterChartIcon className="iconOption" />
      ) : isChooseOption === 1 ? (
        <IconBars className="icon" onClick={() => tradingViewChart?.chart().setChartType(0)} />
      ) : isChooseOption === 2 ? (
        <IconCandles className="iconCandlesSingle" onClick={() => tradingViewChart?.chart().setChartType(1)} />
      ) : isChooseOption === 3 ? (
        <IconHollowCandles className="iconCandles" onClick={() => tradingViewChart?.chart().setChartType(9)} />
      ) : isChooseOption === 4 ? (
        <IconHeikinAshi className="icon" onClick={() => tradingViewChart?.chart().setChartType(8)} />
      ) : isChooseOption === 5 ? (
        <IconLine className="iconLine" onClick={() => tradingViewChart?.chart().setChartType(2)} />
      ) : (
        <IconArea className="icon" onClick={() => tradingViewChart?.chart().setChartType(3)} />
      )}

      <IconRotate isShowFavoredList={isShowFavoredList}>
        <ArrowDownChartIcon className="iconOptionArrowDown" />
      </IconRotate>
    </IconWrapper>
  );
};

export default IconBox;
