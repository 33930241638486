import { isNumber } from 'src/helpers/numberFormatter';

export const TAB_CALCULATOR = [
  'calculator.pnl',
  'calculator.target_price',
  'calculator.liq_price',
  'calculator.max_open',
  'calculator.open_price',
];

export const getNumberRegex = (digit: number, precision: number): RegExp => {
  return new RegExp(`^\\d{0,${digit}}(\\.\\d{0,${precision}})?$`);
};

export const validateNumber = (value: string, setState: any, digit: any, precision: any) => {
  if (!isNumber(value) && value) {
    return;
  }
  const regexNumber = getNumberRegex(digit, precision);
  if (regexNumber.test(value)) {
    setState(value);
  } else {
    value === '' && setState('');
  }
};
