export const COLOR_CHART = {
  red: '#fa8080',
  green: '#57eba1',
  blue: '#9dbff9',
  orange: '#fccc75',
  teal: '#a9eff2',
  yellow: '#fded72',
  purple: '#d8abfc',
};

export enum SYMBOL_TYPE {
  stock = 'stock',
  bitcoin = 'bitcoin',
}

export enum TradingMethod {
  BSCOrderbook = 2,
}

export interface Pair {
  pairs_id: number;
  price_precision: string;
  amount_precision: string;
  group_count: number;
  // base coin
  base_name: string;
  base_symbol: string;
  base_stellar_issuer: string;
  base_bsc_address: string;
  base_type: number;
  base_decimal: number;
  // quote coin
  quote_name: string;
  quote_symbol: string;
  quote_stellar_issuer: string;
  quote_bsc_address: string;
  quote_type: number;
  quote_decimal: number;
}
