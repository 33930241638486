export const errorMessage = {
  AUTHEN: {
    title: 'Unauthorized',
    message: `Please try again.`,
  },
  SERVER_INTERNAL_ERROR: {
    title: 'Server error',
    message: `Sorry, we're having temporary server issues.`,
    subMessage: 'Please try again later!',
  },
  BAD_REQUEST: {
    title: 'Bad request.',
    message: ``,
    subMessage: '',
  },
  EMAIL_ERROR: {
    title: '',
    message: ``,
    subMessage: '',
  },
  PERMISSION_DENIED: {
    title: 'Permission denied.',
    message: ``,
    subMessage: '',
  },
  NO_INTERNET_CONNECTION: {
    title: 'No internet connection!',
    message: `Please try again later.`,
    subMessage: '',
  },
  GET_ALL_ORDERS_ERROR: {
    title: 'Can not get orders',
    message: '',
    subMessage: '',
  },
  CANCEL_ORDER_ERROR: {
    title: 'Can not cancel order',
    message: '',
    subMessage: '',
  },
  GET_TICKER_ERROR: {
    title: 'Can not get ticker',
    message: '',
    subMessage: '',
  },
  GET_INSTRUMENT_ERROR: {
    title: 'Can not get instrument',
    message: '',
    subMessage: '',
  },
  GET_TRADE_HISTORY_ERROR: {
    title: 'Can not get trade history',
    message: '',
    subMessage: '',
  },
  GET_FUNDING_ERROR: {
    title: 'Can not get funding',
    message: '',
    subMessage: '',
  },
  GET_FAV_INSTRUMENT_ERROR: {
    title: 'Can not get favorite instrument',
    message: '',
    subMessage: '',
  },
  UPDATE_FAV_INSTRUMENT_ERROR: {
    title: 'Can not get favorite instrument',
    message: '',
    subMessage: '',
  },
  DEPOSIT_POPUP: {
    exceedAvailable: 'The amount deposited must be lower than the available asset',
  },
  DEPOSIT_FAILED: {
    title: 'Deposit',
    message: 'Deposit unsucessfully!',
    subMessage: 'Please try again!',
  },
  APPROVE_FAILED: {
    title: 'Deposit',
    message: 'Approve unsucessfully!',
    subMessage: 'Please try again!',
  },
  WITHDRAW_AMOUNT_POPUP: {
    belowMinimum: 'The amount withdrawn must be greater than minimun threshold',
    exceedAvailable: 'The amount withdrawn must be lower than the subtraction of Available balance and fee',
  },
  WITHDRAW_MIN_AMOUNT: {
    title: 'Withdraw',
    message: 'Amount must be greater than minimum amount',
  },
  WITHDRAW_FAIL: {
    title: 'Withdraw',
    message: 'Withdraw unsuccessfully!',
    subMessage: 'Please try again!',
  },
  REQUIRED_CONNECT_METAMASK: {
    title: 'Require login',
    message: 'You have to login first!',
  },
  LOGIN_CAN_NOT_GET_MESSAGE: {
    title: 'Request fail',
    message: 'Can not get signature message',
  },
  ENABLE_TRADING_FAIL: {
    title: 'Enale Trading',
    message: 'Fail to enable trading, please try again',
  },
  ORDER_ERROR: {
    title: 'Order fail',
    message: '',
    subMessage: '',
  },
  BLANK_PRICE: {
    title: 'Please input order price',
    message: '',
    subMessage: '',
  },
  BLANK_SIZE: {
    title: 'Please input order size',
    message: '',
    subMessage: '',
  },
  BLANK_STOP_PRICE: {
    title: 'Please input stop price',
    message: '',
    subMessage: '',
  },
  BLANK_CALLBACK_RATE: {
    title: 'Invalid callback rate',
    message: '',
    subMessage: '',
  },
  BLANK_ACTIVATION_PRICE: {
    title: 'Please input activation price',
    message: '',
    subMessage: '',
  },
  BLANK_TPSL: {
    title: 'Please input either TP or SL price or both. Otherwise, please deselect TP/SL option',
    message: '',
    subMessage: '',
  },
  INSUFFICIENT_BALANCE: {
    title: 'Insufficient balance to order',
    message: '',
    subMessage: '',
  },
  ORDER_IMMEDIATELY_TRIGGER: {
    title: 'Order would immediately trigger',
    message: '',
    subMessage: '',
  },
  ORDER_REJECTED_TPSL: {
    title: 'Order rejected. Take profit or stop loss order will be triggered immediately',
    message: '',
    subMessage: '',
  },
  ORDER_REJECTED_REDUCEONLY: {
    title: 'Reduce Only Order is rejected',
    message: '',
    subMessage: '',
  },
  ORDER_REJECTED: {
    title: 'Order rejected',
    message: '',
    subMessage: '',
  },
  INVALID_SIZE: {
    title: 'Invalid size',
    message: '',
    subMessage: '',
  },
  INVALID_ORDER_QUANTITY: {
    title: 'Total order quantity exceeds position size. Please try again.',
    message: '',
    subMessage: '',
  },
  ACCOUNT_NOT_FOUND: {
    title: 'Account Not Found',
    message: 'Please try again.',
    subMessage: '',
  },
};
