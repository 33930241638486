import React from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './DropdownCustom.module.scss';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);
type dataTableType = {
  accountId?: number;
  activationPrice: string;
  callbackRate: null;
  createdAt: string | number;
  displayQuantity: null;
  executedPrice: string;
  id: string;
  isReduceOnly: boolean;
  leverage: string;
  lockPrice: string;
  marginMode: null;
  note: null;
  operationId: string;
  orderValue: string;
  price: string;
  quantity: string;
  remaining: string;
  side: string;
  status: string;
  stopLossOrderId: string | number;
  symbol: string;
  takeProfitOrderId: string | number;
  timeInForce: string;
  stopPrice: string;
  stopType: string;
  isPostOnly: boolean;
  tpSLPrice: string;
  tpSLType: string;
  trailValue: null;
  trigger: string;
  type: string;
  updatedAt: string | number;
  vertexPrice: string;
};

interface DropdownMenuProps {
  open: boolean;
  options: any[];
  active?: string;
  refElm: HTMLImageElement | null;
  handleClose: any;
  handleChooseOption: any;
  dataTable: dataTableType[];
}

const DropdownMenuCustom: React.FC<DropdownMenuProps> = ({
  open,
  options,
  active,
  handleClose,
  refElm,
  handleChooseOption,
  dataTable,
}) => {
  const handleChoose = (value: any) => {
    handleChooseOption(value);
    handleClose();
  };

  const handleClosePopover = () => {
    handleClose();
  };

  const { t } = useTranslation('common');

  return (
    <>
      {open && (
        <ClickAwayListener onClickAway={handleClosePopover}>
          <Popper
            open={open}
            anchorEl={refElm}
            transition
            placement="bottom"
            disablePortal={false}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={cx(dataTable.length !== 0 ? 'modal-paper' : 'modal-paper-empty')}
          >
            <div>
              {options.map((item) => {
                return (
                  <div
                    key={item.value}
                    className={cx('modal-item')}
                    onClick={() => {
                      handleChoose(item.value);
                    }}
                  >
                    <span className={cx(item.value === active ? 'active' : '')}>{t(item.label)}</span>
                  </div>
                );
              })}
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DropdownMenuCustom;
