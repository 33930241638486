import React from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './Dropdown.module.scss';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

interface DropdownMenuProps {
  open: boolean;
  options: any[];
  active?: string;
  refElm: HTMLImageElement | null;
  handleClose: any;
  handleChooseOption: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open,
  options,
  active,
  handleClose,
  refElm,
  handleChooseOption,
}) => {
  const handleChoose = (value: any) => {
    handleChooseOption(value);
    handleClose();
  };

  const handleClosePopover = () => {
    handleClose();
  };

  const { t } = useTranslation('common');

  return (
    <>
      {open && (
        <ClickAwayListener onClickAway={handleClosePopover}>
          <Popper
            open={open}
            anchorEl={refElm}
            transition
            placement="bottom"
            disablePortal={false}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={cx('modal-paper')}
          >
            <div>
              {options.map((item) => {
                return (
                  <div
                    key={item.value}
                    className={cx('modal-item')}
                    onClick={() => {
                      handleChoose(item.value);
                    }}
                  >
                    <span className={cx(item.value === active ? 'active' : '')}>{t(item.label)}</span>
                  </div>
                );
              })}
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DropdownMenu;
