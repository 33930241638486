import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeTrade } from 'src/constants/common.constants';
import axiosInstance from 'src/helpers/config';
// import axiosInstanceLocal from 'src/helpers/configAPILocal';

export interface Instrument {
  id: number;
  symbol: string;
  rootSymbol: string;
  state: string;
  type: number;
  expiry: string;
  baseUnderlying: string;
  quoteCurrency: string;
  underlyingSymbol: string;
  settleCurrency: string;
  initMargin: string;
  maintainMargin: string;
  deleverageable: boolean;
  makerFee: string;
  takerFee: string;
  settlementFee: string;
  hasLiquidity: boolean;
  referenceIndex: string;
  settlementIndex: string;
  fundingBaseIndex: string;
  fundingQuoteIndex: string;
  fundingPremiumIndex: string;
  fundingInterval: number;
  tickSize: string;
  contractSize: string;
  lotSize: string;
  maxPrice: string;
  maxOrderQty: number;
  multiplier: string;
  optionStrikePrice: string;
  optionKoPrice: string;
  riskLimit: string;
  riskStep: string;
  rank: number;
  createdAt: string;
  updatedAt: string;
  isFavorite?: boolean;
  name?: string;
  minPriceMovement?: string;
  contractType?: string;
  minQtyMovement?: string;
}

const initialInstrument = {
  id: 1,
  symbol: '',
  rootSymbol: '',
  state: '',
  type: 0,
  expiry: '',
  baseUnderlying: '',
  quoteCurrency: '',
  underlyingSymbol: '',
  settleCurrency: '',
  initMargin: '',
  maintainMargin: '',
  deleverageable: true,
  makerFee: '',
  takerFee: '',
  settlementFee: '',
  hasLiquidity: false,
  referenceIndex: '',
  settlementIndex: '',
  fundingBaseIndex: '',
  fundingQuoteIndex: '',
  fundingPremiumIndex: '',
  fundingInterval: 0,
  tickSize: '',
  contractSize: '',
  lotSize: '',
  maxPrice: '',
  maxOrderQty: 0,
  multiplier: '',
  optionStrikePrice: '',
  optionKoPrice: '',
  riskLimit: '',
  riskStep: '',
  rank: 0,
  createdAt: '',
  updatedAt: '',
  minQtyMovement: '',
};

const initialState: {
  instrument: Instrument[];
  currentInstrument: Instrument;
  favIns: Instrument[];
  favoriteInstruments: { symbol: string }[];
  loading: boolean;
} = {
  instrument: [initialInstrument],
  favIns: [],
  currentInstrument: initialInstrument,
  favoriteInstruments: [],
  loading: false,
};

export const getInstrument = createAsyncThunk('getInstrument', async () => {
  // return await axiosInstanceLocal.get('/instruments');
  return await axiosInstance.get('/instruments');
});

export const getFavInstrument = createAsyncThunk('getFavoriteInstrument', async () => {
  return await axiosInstance.get(`/user/favorite`);
});

export const updateFavInstrument = createAsyncThunk('updateFavoriteInstrument', async (body: any) => {
  return await axiosInstance.post(`/user/favorite`, body);
});

export const instrumentSlice = createSlice({
  name: 'instrument',
  initialState,
  reducers: {
    setCurrentInstrument: (state, action: PayloadAction<Instrument>) => {
      state.currentInstrument = action.payload;
    },
    setFavIns: (state, action) => {
      state.favIns = action.payload;
    },
  },
  extraReducers: {
    [getInstrument.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getInstrument.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getInstrument.fulfilled.toString()]: (state, action) => {
      const removeLTC = action.payload.data && action.payload.data.filter((item: any) => item.symbol !== 'LTCUSDM');
      const removeUSD = removeLTC.filter((item: any) => {
        if (item.contractType === 'USD_M') {
          return item.quoteCurrency !== 'USD';
        }
        return item;
      });

      state.instrument = removeUSD;
      const url = new URL(window.location.href);
      const pathname = url.pathname;

      if (
        (state.currentInstrument.id === -1 &&
          pathname === `/futures/${TypeTrade.USD_M}/${action.payload.data[0].symbol}`) ||
        !pathname.includes('/futures/')
      ) {
        state.currentInstrument = action.payload.data[0];
      }
      state.loading = false;
    },
    [getFavInstrument.fulfilled.toString()]: (state, action) => {
      state.favoriteInstruments = action.payload.data;
    },
    [updateFavInstrument.fulfilled.toString()]: (state, action) => {
      const { symbol, isFavorite } = action.payload.data;
      if (!isFavorite) {
        state.favoriteInstruments = state.favoriteInstruments.filter((instrument) => instrument.symbol !== symbol);
      } else {
        const instrument = state.favoriteInstruments.find((instrument) => instrument.symbol === symbol);
        if (!instrument) {
          state.favoriteInstruments = [...state.favoriteInstruments, { symbol }];
        }
      }
    },
  },
});

export const { setCurrentInstrument, setFavIns } = instrumentSlice.actions;

const { reducer: instrumentReducer } = instrumentSlice;

export default instrumentReducer;
