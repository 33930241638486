import React, { useCallback, useRef } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/components/cores/SelectBox/index.module.scss';
import { linkIcon, DropIcon, DropIconDark, TransferIcon, DepositIcon, WithdrawIcon } from 'src/assets/icon';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

export type TOption = {
  label: string;
  value?: string | number;
  icon?: string;
  href?: string;
  onAction?: () => void;
};

interface Props {
  title?: string;
  iconTitle?: string;
  options?: TOption[];
  isIcon?: boolean;
  noLink?: boolean;
  isWallet?: boolean;
}

const SelectBox: React.FC<Props> = ({ title, iconTitle, options, isIcon, noLink, isWallet }) => {
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation('common');

  const handleOnTitleMouseEntered = useCallback(() => {
    if (!rfTitle.current) return;
    if (!rfUnderPanel.current) return;
    if (
      window.innerWidth - rfTitle.current.getBoundingClientRect().right <
      rfUnderPanel.current.getBoundingClientRect().width + 20
    ) {
      rfUnderPanel.current.style.right = '0px';
    }
  }, []);

  return (
    <div className={cx('select-wrapper')}>
      <ul>
        <li onMouseEnter={handleOnTitleMouseEntered}>
          {isIcon ? (
            <div className={cx('select-title')} ref={rfTitle}>
              <img src={iconTitle} />
            </div>
          ) : (
            <div className={cx('select-title')} ref={rfTitle}>
              {title}
              {theme === THEME_MODE.LIGHT
                ? options && <DropIcon className={cx('icon')} />
                : options && <DropIconDark className={cx('icon')} />}
            </div>
          )}

          {options && (
            <ul className={cx('option')} ref={rfUnderPanel}>
              {isWallet && (
                <div className={cx('action-wallet')}>
                  <a href={process.env.REACT_APP_DOMAIN_SPOT + '/funds/deposits-wallet?coin=usdt'}>
                    <div className={cx('action-wallet-item')}>
                      <DepositIcon />
                      <div>{t('header.deposit')}</div>
                    </div>
                  </a>

                  <a href={process.env.REACT_APP_DOMAIN_SPOT + '/funds/withdraw-wallet?coin=usdt'}>
                    <div className={cx('action-wallet-item')}>
                      <WithdrawIcon />
                      <div>{t('header.withdraw')}</div>
                    </div>
                  </a>

                  <a href={process.env.REACT_APP_DOMAIN_SPOT + '/funds/transfer?symbol=usdt'}>
                    <div className={cx('action-wallet-item')}>
                      <TransferIcon />
                      <div>{t('header.transfer')}</div>
                    </div>
                  </a>
                </div>
              )}
              {options?.map((option, index) => (
                <a
                  key={index}
                  className={cx('option-value', noLink && 'value-no-link')}
                  href={option.href}
                  onClick={option.onAction}
                >
                  {option.icon && <img style={{ marginLeft: '15px' }} width={25} height={25} src={option.icon} />}
                  {option.label && <div className={cx('option-text')}>{option.label}</div>}
                  {!noLink && <img className={cx('link-icon')} src={linkIcon} />}
                </a>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SelectBox;
