import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CInput } from 'src/components/cores/Input';
import BigNumber from 'bignumber.js';
import { countCharacter, formatNumber, formatRoundDown, handleNumber } from 'src/helpers/numberFormatter';
import { useAppSelector } from 'src/store/hooks';
import useScreen from 'src/hooks/useScreen';
import { ITicker } from 'src/services/ticker';
import { validateNumber } from './constants';
import { SliderRange } from 'src/components/SliderRange/SliderRangeMUI';
import TooltipText from 'src/components/cores/TooltipText';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { TypeTrade } from 'src/constants/common.constants';
import { getCookieStorage } from 'src/helpers/storage';
import { getAvailableBalance } from 'src/helpers/balanceCalculator';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helpers/config';

const cx = classnames.bind(styles);

export const MaxOpenCalculator = (props: any) => {
  const [isLong, setIsLong] = useState(true);
  const [entryPrice, setEntryPrice] = useState('');
  const [balance, setBalance] = useState('');
  const [leverage, setLeverage] = useState(20);
  const [result, setResult] = useState({ maxOpenCoin: '', maxOpenUSDT: '' });
  const leverageMarginCache = useAppSelector((state) => state.masterdataFuture.leverageMarginCache);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const account = useSelector((state: RootState) => state.account.account);

  const { isMobile } = useScreen();
  const { t } = useTranslation('common');
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const isExpired = getCookieStorage('isExpired');
  const token = getCookieStorage('access_token');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined' && account && account[0]?.id;

  // eslint-disable-next-line max-len
  // Max Open means max position of  that coin which allow set an order, It depends on balance, leverage available and limit by trading rules ( mention later ) of that pair
  // Max Open in USDT = Balance * Leverage (Max must following Trading rule)
  // Max Open in Coin = Max Open in USDT / Entry Price

  const maxLeverage = tradingRulesCache?.filter((item) => item.symbol === props?.pair.symbol);
  const levelOpen = leverageMarginCache?.filter(
    (item) => leverage <= item.maxLeverage && props?.pair.symbol === item?.symbol,
  );

  const leveOpenSort = levelOpen.sort(function (a, b) {
    return a.maxLeverage - b.maxLeverage;
  });

  const handleCalculator = () => {
    const isCoinM = props?.pair?.contractType === TypeTrade.COIN_M;
    if (isCoinM) {
      const multiplier = props?.pair?.multiplier || 100;
      const maxOpenCont = new BigNumber(balance)
        .times(leverage)
        .times(entryPrice)
        .div(multiplier)
        .toFixed(0, BigNumber.ROUND_DOWN);

      const maxOpenCoin = BigNumber.minimum(new BigNumber(balance).times(leverage), leveOpenSort[0]?.max);

      setResult({
        maxOpenCoin: formatNumber(maxOpenCont.toString(), 2, '-'),
        maxOpenUSDT: formatNumber(maxOpenCoin.toString(), 2, '-'),
      });
    } else {
      const maxOpenUSDT = new BigNumber(balance).times(leverage);
      const maxOpenUSDTFinal =
        Number(leveOpenSort[0]?.max) < Number(maxOpenUSDT) && leverage > 1 ? Number(leveOpenSort[0]?.max) : maxOpenUSDT;
      const maxOpenCoin = new BigNumber(maxOpenUSDTFinal).div(entryPrice);

      setResult({
        maxOpenCoin: formatNumber(maxOpenCoin.toString(), 2, '-'),
        maxOpenUSDT: formatNumber(maxOpenUSDTFinal.toString(), 2, '-'),
      });
    }
  };

  useEffect(() => {
    setLeverage(20);
  }, [props?.pair]);

  useEffect(() => {
    if (isAuthenticated) {
      const availablebalance = getAvailableBalance(!isCoinM ? props.pair?.quoteCurrency : props.pair.rootSymbol);
      setBalance(formatRoundDown(availablebalance, 2));
    }
  }, [isAuthenticated, props.pair]);

  const handleGetLast = async () => {
    const tickers = await axiosInstance.get('/ticker/24h');
    if (tickers.data) {
      const currentLast = tickers.data.filter((item: ITicker) => item.symbol === props.pair.symbol);
      setEntryPrice(currentLast[0]?.lastPrice || '');
    }
  };

  return (
    <div className={cx(isMobile ? 'wrapper-content-mb' : 'wrapper-content')}>
      {/* Content Left */}
      <div className={cx(isMobile ? 'wrapper-content-mb__left' : 'wrapper-content__left')}>
        <div className={cx(isMobile ? 'wrapper-content-mb__left__type-trade' : 'wrapper-content__left__type-trade')}>
          <div onClick={() => setIsLong(true)} className={cx(isLong && 'type-active')}>
            {t('calculator.long')}
          </div>
          <div onClick={() => setIsLong(false)} className={cx(!isLong && 'type-active-short')}>
            {t('calculator.short')}
          </div>
        </div>

        {isMobile && <div className={cx('mt-52')}></div>}

        <SliderRange
          value={leverage || 20}
          handleRange={(value: number) => setLeverage(value)}
          maxRange={maxLeverage[0]?.maxLeverage || 125}
          type="calculator"
        />
        {leverage > 1 && (
          <>
            {' '}
            <div className={cx(isMobile ? 'text-medium-thin-mb' : 'text-medium-thin')}>
              {t('trading.max_position')}:{' '}
            </div>
            <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')} style={{ marginTop: '6px' }}>
              {formatNumber(leveOpenSort[0]?.max.toString(), 2, '0')}{' '}
              {isCoinM ? props.pair?.rootSymbol : props.pair?.quoteCurrency}
            </div>
          </>
        )}

        {/* Entry Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.entry_price')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_price')}
              size="sm"
              outline={false}
              valueHandSet={entryPrice}
              onChange={(value: string): void => {
                validateNumber(value, setEntryPrice, 10, 2);
              }}
            />
            <div className={cx('text-last')} onClick={handleGetLast}>
              {t('order.last')}
            </div>
            <div className={cx('token-symbol')} style={{ paddingLeft: '0px' }}>
              {props.pair?.quoteCurrency}
            </div>
          </div>
        </div>

        {/* Balance */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('margin.balance')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_balance')}
              size="sm"
              outline={false}
              valueHandSet={balance}
              onChange={(value: string): void => {
                validateNumber(value, setBalance, 10, 2);
              }}
            />
            <div className={cx('token-symbol')}>{isCoinM ? props.pair.rootSymbol : props.pair.quoteCurrency}</div>
          </div>
        </div>

        {/* Button Calculate */}

        {!isMobile && (
          <button className={cx('button-calculate')} disabled={!entryPrice || !balance} onClick={handleCalculator}>
            {t('calculator.calculate')}
          </button>
        )}
      </div>

      {/* Content Right */}

      <div className={cx(isMobile ? 'wrapper-content-mb__right' : 'wrapper-content__right', 'wrapper-right-maxopen')}>
        <div>
          <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')}>{t('calculator.result')}</div>
          <div className={cx('result-content')}>
            <div className={cx(isMobile ? 'text-open-mb' : 'text-open')}>
              {t('calculator.max_open')} ({isCoinM ? 'Cont' : props.pair?.rootSymbol})
            </div>
            <TooltipText text={countCharacter(result.maxOpenCoin) > 10 ? result.maxOpenCoin : ''}>
              <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
                <div className={cx(result.maxOpenCoin.length > 0 ? 'text-value' : 'text-value-empty')}>
                  {handleNumber(result.maxOpenCoin, 10) || '-'}
                </div>
              </div>
            </TooltipText>
          </div>
          <div className={cx('result-content')}>
            <div className={cx(isMobile ? 'text-open-mb' : 'text-open')}>
              {t('calculator.max_open')} ({isCoinM ? props.pair?.rootSymbol : props.pair?.quoteCurrency})
            </div>
            <TooltipText text={countCharacter(result.maxOpenUSDT) > 10 ? result.maxOpenUSDT : ''}>
              <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
                <div className={cx(result.maxOpenUSDT.length > 0 ? 'text-value' : 'text-value-empty')}>
                  {handleNumber(result.maxOpenUSDT, 10) || '-'}
                </div>
              </div>
            </TooltipText>
          </div>
        </div>

        <div className={cx(isMobile ? 'text-medium-thin-mobile' : 'text-medium-thin')}>
          {t('calculator.max_open_noti')}
        </div>
      </div>

      {isMobile && (
        <button className={cx('button-calculate')} disabled={!entryPrice || !balance} onClick={handleCalculator}>
          {t('calculator.calculate')}
        </button>
      )}
    </div>
  );
};
