import React from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/Preference/PreferenceModal.module.scss';
import SwitchButton from 'src/components/cores/SwitchBox/SwitchButton';
import { noti } from 'src/hooks/useNoti';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import axiosInstance from 'src/helpers/config';
import { useAppDispatch } from 'src/store/hooks';
import { getPreference } from 'src/services/account';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type TField = 'limitOrder' | 'marketOrder' | 'stopLimitOrder' | 'stopMarketOrder' | 'traillingStopOrder';

export const OrderConfirmation = () => {
  const { t } = useTranslation('common');
  const userSetting = useSelector((state: RootState) => state.account.userSetting);
  const dispatch = useAppDispatch();

  const handlePostSetting = async (field: TField) => {
    try {
      const params = {
        [field]: !userSetting[field],
      };
      await axiosInstance.post('/user-setting/preference', params);
      dispatch(getPreference());
      noti.success({
        title: `${t('tradingRule.order_confirm_setting')}`,
        message: `${t('tradingRule.order_confirm_success')}`,
      });
    } catch (error) {}
  };

  return (
    <div className={cx('order-content')}>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.limit_order')}</div>
        <div>
          <SwitchButton checked={userSetting.limitOrder} onClick={() => handlePostSetting('limitOrder')} />
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.market_order')}</div>
        <div>
          <SwitchButton checked={userSetting.marketOrder} onClick={() => handlePostSetting('marketOrder')} />
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.stop_limit_order')}</div>
        <div>
          <SwitchButton checked={userSetting.stopLimitOrder} onClick={() => handlePostSetting('stopLimitOrder')} />
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.stop_market_order')}</div>
        <div>
          <SwitchButton checked={userSetting.stopMarketOrder} onClick={() => handlePostSetting('stopMarketOrder')} />
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.trailing_stop_order')}</div>
        <div>
          <SwitchButton
            checked={userSetting.traillingStopOrder}
            onClick={() => handlePostSetting('traillingStopOrder')}
          />
        </div>
      </div>
      <div className={cx('note')}>{t('tradingRule.order_confirm_enabled')}</div>
    </div>
  );
};
