import { useEffect, useState } from 'react';

const handleTime = (time: number) => {
  return time >= 10 ? time : '0' + time;
};
const getReturnValues = (countDown: any) => {
  //   const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [handleTime(hours), handleTime(minutes), handleTime(seconds)];
};

const useCountdown = (targetDate: any) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return !targetDate || countDown < 0 || !countDown ? ['00', '00', '00'] : getReturnValues(countDown);
};

export { useCountdown };
