import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CInput } from 'src/components/cores/Input';
import BigNumber from 'bignumber.js';
import { countCharacter, formatNumber, handleNumber } from 'src/helpers/numberFormatter';
import { useAppSelector } from 'src/store/hooks';
import useScreen from 'src/hooks/useScreen';
import { validateNumber } from './constants';
import { SliderRange } from 'src/components/SliderRange/SliderRangeMUI';
import TooltipText from 'src/components/cores/TooltipText';
import { TypeTrade } from 'src/constants/common.constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

export const TargetPriceCalculator = (props: any) => {
  const leverageMarginCache = useAppSelector((state) => state.masterdataFuture.leverageMarginCache);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const [isLong, setIsLong] = useState(true);
  const [entryPrice, setEntryPrice] = useState('');
  const [ROE, setROE] = useState('');
  const [leverage, setLeverage] = useState(20);
  const [targetPrice, setTargetPrice] = useState('');
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const { isMobile } = useScreen();
  const { t } = useTranslation('common');
  const maxLeverage = tradingRulesCache?.filter((item) => item.symbol === props?.pair.symbol);
  const levelOpen = leverageMarginCache?.filter(
    (item) => leverage <= item.maxLeverage && props?.pair.symbol === item?.symbol,
  );

  const leveOpenSort = levelOpen.sort(function (a, b) {
    return a.maxLeverage - b.maxLeverage;
  });

  const handleCalculator = () => {
    let result;
    if (props?.pair?.contractType === TypeTrade.COIN_M) {
      //Side * Entry price * Leverage / (Side * Leverage - ROE)
      const side = isLong ? 1 : -1;
      result = new BigNumber(side)
        .times(entryPrice)
        .times(leverage)
        .div(new BigNumber(side).times(leverage).minus(new BigNumber(ROE).div(100)));
    } else {
      // Long TargetPrice = Entry Price * ( ROE% * IMR + 1)
      // Short target price = entry price * ( 1 - ROE% / leverage )

      const IMR = new BigNumber(1).div(leverage);

      result = isLong
        ? new BigNumber(entryPrice).times(new BigNumber(ROE).div(100).times(IMR).plus(1))
        : new BigNumber(entryPrice).times(new BigNumber(1).minus(new BigNumber(ROE).div(100).div(leverage)));
    }
    setTargetPrice(formatNumber(result.toString(), 2, '-'));
  };

  useEffect(() => {
    setLeverage(20);
  }, [props?.pair]);

  return (
    <div className={cx(isMobile ? 'wrapper-content-mb' : 'wrapper-content')}>
      {/* Content Left */}
      <div className={cx(isMobile ? 'wrapper-content-mb__left' : 'wrapper-content__left')}>
        <div className={cx(isMobile ? 'wrapper-content-mb__left__type-trade' : 'wrapper-content__left__type-trade')}>
          <div onClick={() => setIsLong(true)} className={cx(isLong && 'type-active')}>
            {t('calculator.long')}
          </div>
          <div onClick={() => setIsLong(false)} className={cx(!isLong && 'type-active-short')}>
            {t('calculator.short')}
          </div>
        </div>

        {isMobile && <div className={cx('mt-52')}></div>}

        <SliderRange
          handleRange={(value: number) => setLeverage(value)}
          value={leverage}
          maxRange={maxLeverage[0]?.maxLeverage || 125}
          type="calculator"
        />

        {leverage > 1 && (
          <>
            {' '}
            <div className={cx(isMobile ? 'text-medium-thin-mb' : 'text-medium-thin')}>
              {t('trading.max_position')}:{' '}
            </div>
            <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')} style={{ marginTop: '6px' }}>
              {formatNumber(leveOpenSort[0]?.max.toString(), 2, '0')}{' '}
              {isCoinM ? props.pair?.rootSymbol : props.pair?.quoteCurrency}
            </div>
          </>
        )}

        {/* Entry Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.entry_price')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_price')}
              size="sm"
              outline={false}
              valueHandSet={entryPrice}
              onChange={(value: string): void => {
                validateNumber(value, setEntryPrice, 10, 2);
              }}
            />
            <div className={cx(isMobile ? 'token-symbol-mb' : 'token-symbol')}>{props.pair?.quoteCurrency}</div>
          </div>
        </div>

        {/* ROE */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('calculator.roe')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_roe')}
              size="sm"
              outline={false}
              valueHandSet={ROE}
              onChange={(value: string): void => {
                validateNumber(value, setROE, 4, 2);
              }}
            />
            <div className={cx(isMobile ? 'token-symbol-mb' : 'token-symbol')}>%</div>
          </div>
        </div>

        {/* Button Calculate */}

        {!isMobile && (
          <button className={cx('button-calculate')} disabled={!entryPrice || !ROE} onClick={handleCalculator}>
            {t('calculator.calculate')}
          </button>
        )}
      </div>

      {/* Content Right */}

      <div className={cx(isMobile ? 'wrapper-content-mb__right' : 'wrapper-content__right')}>
        <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')}>{t('calculator.result')}</div>
        <div className={cx('result-content')}>
          <div className={cx('text-result')}>{t('calculator.target_price')}</div>
          <TooltipText text={countCharacter(targetPrice) > 10 ? targetPrice : ''}>
            <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
              <div className={cx(targetPrice.length > 0 ? 'text-value' : 'text-value-empty')}>
                {targetPrice.includes('-') ? '--' : handleNumber(targetPrice, 10) || '-'}
              </div>
              <div className={cx(targetPrice.length > 0 ? '' : 'text-pair')}>{props.pair?.quoteCurrency}</div>
            </div>
          </TooltipText>
        </div>
      </div>

      {isMobile && (
        <button className={cx('button-calculate')} disabled={!entryPrice || !ROE} onClick={handleCalculator}>
          {t('calculator.calculate')}
        </button>
      )}
    </div>
  );
};
