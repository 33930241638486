import classnames from 'classnames/bind';
import moment from 'moment';
import React from 'react';
import TooltipNumber from 'src/components/cores/Tooltip';
import styles from 'src/features/OrderbookTrade/Orderbook.module.scss';
import { formatPriceRoundDown } from 'src/helpers/numberFormatter';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';

const cx = classnames.bind(styles);

interface ITable {
  thead?: string[];
  tbody: any[];
  color?: string;
  textColor?: string;
}

const MarketTradeTable: React.FC<ITable> = ({ thead, tbody }: ITable) => {
  const currentInstrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const isReady = useAppSelector((state) => state.marketTrade.isReady);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache.filter((item) => item.symbol === currentInstrument.symbol);
  const maxFiguresForPrice = curTradingRule[0]?.maxFiguresForPrice;
  const maxFiguresForSize = curTradingRule[0]?.maxFiguresForSize;
  return (
    <div className={cx('table-wrapper')}>
      <table className={cx('table')}>
        <thead>
          <tr>
            {thead?.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody className={cx('scrollbar')}>
          {tbody.map((item, idx) => {
            return (
              currentInstrument.symbol === item.symbol && (
                <tr key={idx} className={cx('table-row')}>
                  <td className={item.buyerIsTaker ? 'App-positive-value' : 'App-negative-value'}>
                    {item.price
                      ? // <TooltipNumber defaultValue={item.price} characters={8} decimal={Number(maxFiguresForPrice)} />
                        formatPriceRoundDown(item.price, Number(maxFiguresForPrice))
                      : '\u2002'}
                  </td>
                  <td>
                    {item.quantity ? (
                      <TooltipNumber defaultValue={item.quantity} characters={12} decimal={Number(maxFiguresForSize)} />
                    ) : (
                      '\u2002'
                    )}
                  </td>
                  {<td>{item.createdAt ? moment(new Date(item.createdAt).getTime() + 500).format('HH:mm:ss') : ''}</td>}
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MarketTradeTable;
