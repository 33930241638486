import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CInput } from 'src/components/cores/Input';
import BigNumber from 'bignumber.js';
import { countCharacter, formatNumber, formatRoundDown, handleNumber } from 'src/helpers/numberFormatter';
import { useAppSelector } from 'src/store/hooks';
import { iconDown } from 'src/assets/icon';
import useOnClickOutside from 'src/hooks/useClickOutside';
import useScreen from 'src/hooks/useScreen';
import { validateNumber } from './constants';
import { SliderRange } from 'src/components/SliderRange/SliderRangeMUI';
import TooltipText from 'src/components/cores/TooltipText';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { TypeTrade } from 'src/constants/common.constants';
import { getCookieStorage } from 'src/helpers/storage';
import { getAvailableBalance } from 'src/helpers/balanceCalculator';
import { useTranslation } from 'react-i18next';
import { multiplierIns } from 'src/helpers/PositionCalculator';

const ARR_MODE = ['trading.cross', 'trading.isolated'];
const cx = classnames.bind(styles);
export const LiquidationCalculator = (props: any) => {
  const leverageMarginCache = useAppSelector((state) => state.masterdataFuture.leverageMarginCache);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const account = useSelector((state: RootState) => state.account.account);
  const [isLong, setIsLong] = useState(true);
  const [entryPrice, setEntryPrice] = useState('');
  const [balance, setBalance] = useState('');
  const [quantity, setQuantity] = useState('');
  const [leverage, setLeverage] = useState(20);
  const [optionType, setOptionType] = useState('trading.cross');
  const [liquidationResult, setLiquidationResult] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const { t } = useTranslation('common');
  const el = useRef(null);

  const { isMobile } = useScreen();

  const isExpired = getCookieStorage('isExpired');
  const token = getCookieStorage('access_token');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined' && account && account[0]?.id;

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const maxLeverage = tradingRulesCache?.filter((item) => item.symbol === props?.pair.symbol);
  const levelOpen = leverageMarginCache?.filter(
    (item) => leverage <= item.maxLeverage && props?.pair.symbol === item?.symbol,
  );

  const leveOpenSort = levelOpen.sort(function (a, b) {
    return a.maxLeverage - b.maxLeverage;
  });

  const isCoinM = props?.pair?.contractType === TypeTrade.COIN_M;
  const multiplier = multiplierIns(props?.pair?.symbol) || 0;

  const mulEntryQuantity = isCoinM
    ? new BigNumber(quantity).times(multiplier).div(entryPrice).toNumber()
    : new BigNumber(entryPrice).times(quantity).toNumber();

  const getMarginRateAndAmount = () => {
    let maintenanceMarginRate = 0,
      maintenanceAmount = 0;
    const notionalValue = isCoinM
      ? new BigNumber(quantity).times(multiplier).div(entryPrice)
      : new BigNumber(entryPrice).times(quantity);

    if (leverageMarginCache) {
      const listTierCurrent = leverageMarginCache.filter((item: any) => item?.symbol === props?.pair?.symbol);
      const listTierResult = listTierCurrent.filter(
        (item: any) => Number(notionalValue) <= item.max && Number(notionalValue) >= item.min,
      );
      const listTierFinal = listTierResult.length > 0 ? listTierResult[0] : listTierCurrent[listTierCurrent.length - 1];
      maintenanceMarginRate = listTierFinal?.maintenanceMarginRate / 100;
      maintenanceAmount = Number(listTierFinal?.maintenanceAmount);
    }

    return { maintenanceMarginRate, maintenanceAmount };
  };

  const handleCalculator = () => {
    const WB = balance,
      TMM = 0,
      UPNL1 = 0,
      cumB = getMarginRateAndAmount().maintenanceAmount,
      Side = isLong ? 1 : -1;
    const MMR = getMarginRateAndAmount().maintenanceMarginRate;
    let resultCalculator;

    if (mulEntryQuantity === Number(WB)) {
      setLiquidationResult('0.00');
      return;
    }
    if (mulEntryQuantity < Number(WB)) {
      setLiquidationResult('--');
      return;
    }

    if (isCoinM) {
      //Liquidation Price =  (Quantity * MMR + Side * Quantity) / [(Wallet Balance + CumB) / Contract size + Side * Quantity/ Entry price]
      const multiplier = props?.pair?.multiplier;
      resultCalculator =
        (Number(quantity) * MMR + Side * Number(quantity)) /
        ((Number(WB) + cumB) / Number(multiplier) + (Side * Number(quantity)) / Number(entryPrice));
    } else {
      //   Liquidation Price = (WB - TMM + UPNL1 + cumB - Side * quantity * Entry Price)/ (quantity * MMR - Side * quantity)

      resultCalculator =
        (Number(WB) - TMM + UPNL1 + cumB - Side * Number(quantity) * Number(entryPrice)) /
        (Number(quantity) * MMR - Side * Number(quantity));
    }

    setLiquidationResult(resultCalculator >= 0 ? formatNumber(resultCalculator.toString(), 2, '-') : '-');
  };

  const balanceError = isCoinM
    ? new BigNumber(balance).lt(new BigNumber(quantity).times(multiplier).div(leverage).div(entryPrice))
    : new BigNumber(balance).lt(new BigNumber(entryPrice).times(quantity).div(leverage));

  useEffect(() => {
    setLeverage(20);
  }, [props?.pair]);

  useEffect(() => {
    if (isAuthenticated) {
      const availablebalance = getAvailableBalance(!isCoinM ? props.pair?.quoteCurrency : props.pair.rootSymbol);
      setBalance(formatRoundDown(availablebalance, 2));
    }
  }, [isAuthenticated, props.pair]);

  return (
    <div className={cx(isMobile ? 'wrapper-content-mb' : 'wrapper-content')}>
      {/* Content Left */}
      <div
        className={cx(isMobile ? 'wrapper-content-mb__left' : 'wrapper-content__left')}
        style={{ position: 'relative' }}
      >
        <div className={cx('wrapper-content-select')}>
          <div className={cx('select-wrapper', 'pt-none')} ref={el} style={{ width: '86%' }}>
            <div
              className={cx('select-value', 'type')}
              onClick={() => setOpenSelect(!openSelect)}
              style={{ margin: 0, width: '100%' }}
            >
              <div>{t(optionType)}</div>
              <img className={cx(openSelect && 'rotate')} src={iconDown} />
            </div>

            {openSelect && (
              <div
                className={cx('select-list', 'pt-liq')}
                style={{ margin: 0, paddingTop: '0', width: isMobile ? '124px' : '211px' }}
              >
                {ARR_MODE.map((item, index) => (
                  <div
                    className={cx('select-item', optionType === item && 'select-item-active')}
                    onClick={() => {
                      setOptionType(item);
                      setOpenSelect(false);
                    }}
                    style={{ cursor: 'pointer' }}
                    key={index}
                  >
                    {t(item)}
                  </div>
                ))}
              </div>
            )}
          </div>

          <span className={cx('mode')}>{t('calculator.one_way_mode')}</span>
        </div>
        <div className={cx(isMobile ? 'wrapper-content-mb__left__type-trade' : 'wrapper-content__left__type-trade')}>
          <div onClick={() => setIsLong(true)} className={cx(isLong && 'type-active')}>
            {t('calculator.long')}
          </div>
          <div onClick={() => setIsLong(false)} className={cx(!isLong && 'type-active-short')}>
            {t('calculator.short')}
          </div>
        </div>

        {isMobile && <div className={cx('mt-52')}></div>}

        <SliderRange
          value={leverage || 20}
          handleRange={(value: number) => setLeverage(value)}
          maxRange={maxLeverage[0]?.maxLeverage || 125}
          type="calculator"
        />

        {leverage > 1 && (
          <>
            {' '}
            <div className={cx('text-medium-thin')}>{t('trading.max_position')}: </div>
            <div className={cx('text-medium')} style={{ marginTop: '6px' }}>
              {formatNumber(leveOpenSort[0]?.max.toString(), 2, '0')}{' '}
              {isCoinM ? props.pair?.rootSymbol : props.pair?.quoteCurrency}
            </div>
          </>
        )}

        {/* Entry Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.entry_price')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_price')}
              size="sm"
              outline={false}
              valueHandSet={entryPrice}
              onChange={(value: string): void => {
                validateNumber(value, setEntryPrice, 10, 2);
              }}
            />
            <div className={cx('token-symbol')}>{props.pair?.quoteCurrency}</div>
          </div>
        </div>

        {/* Quantity Price */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('order.quantity')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_quantity')}
              size="sm"
              outline={false}
              valueHandSet={quantity}
              onChange={(value: string): void => {
                validateNumber(value, setQuantity, 10, 3);
              }}
            />
            <div className={cx('token-symbol')}>{isCoinM ? 'Cont' : props.pair.rootSymbol}</div>
          </div>
        </div>

        {/* Balance */}
        <div className={cx('wrapper-input')}>
          <div className={cx(isMobile ? 'input-title-mb' : 'input-title')}>{t('margin.balance')}</div>
          <div className={cx('input-group')}>
            <CInput
              classNamePrefixWrapper={cx('input')}
              classNamePrefix={cx(isMobile ? 'input-mb' : '')}
              type="text"
              placeholder={t('calculator.input_balance')}
              size="sm"
              outline={false}
              valueHandSet={balance}
              onChange={(value: string): void => {
                validateNumber(value, setBalance, 10, 2);
              }}
            />
            <div className={cx('token-symbol')}>{isCoinM ? props.pair.rootSymbol : props.pair.quoteCurrency}</div>
          </div>

          {balanceError && <div className={cx('error-maximum-insufficient')}>{t('calculator.error_balance')}</div>}
        </div>

        {/* Button Calculate */}

        {!isMobile && (
          <>
            <button
              className={cx('button-calculate')}
              disabled={
                !entryPrice ||
                !quantity ||
                !balance ||
                (new BigNumber(mulEntryQuantity).gt(leveOpenSort[0]?.max) && leverage > 1) ||
                balanceError
              }
              onClick={handleCalculator}
            >
              {t('calculator.calculate')}
            </button>
            {new BigNumber(mulEntryQuantity).gt(leveOpenSort[0]?.max) && leverage > 1 && (
              <div className={cx('error-maximum')}>{t('calculator.exceeded')}</div>
            )}
          </>
        )}
      </div>

      {/* Content Right */}

      <div className={cx(isMobile ? 'wrapper-content-mb__right' : 'wrapper-content__right', 'wrapper-right-maxopen')}>
        <div>
          <div className={cx(isMobile ? 'text-medium-mb' : 'text-medium')}>{t('calculator.result')}</div>
          <div className={cx('result-content')}>
            <div className={cx('text-result')}>{t('calculator.liq_price')}</div>
            <TooltipText
              text={
                liquidationResult && liquidationResult !== '--' && countCharacter(liquidationResult) > 10
                  ? liquidationResult
                  : ''
              }
            >
              <div className={cx(isMobile ? 'tooltip-value-mb' : 'tooltip-value')}>
                <div className={cx(liquidationResult.length > 0 ? 'text-value' : 'text-value-empty')}>
                  {handleNumber(liquidationResult, 10) || '-'}
                </div>
                <div className={cx(liquidationResult.length > 0 ? '' : 'text-pair')}>{props.pair?.quoteCurrency}</div>
              </div>
            </TooltipText>
          </div>
        </div>

        <div className={cx(isMobile ? 'text-medium-thin-mobile' : 'text-medium-thin')}>{t('calculator.liq_noti')}</div>
      </div>
      {isMobile && (
        <>
          <button
            className={cx('button-calculate')}
            disabled={
              !entryPrice ||
              !quantity ||
              !balance ||
              (new BigNumber(mulEntryQuantity).gt(leveOpenSort[0]?.max) && leverage > 1) ||
              balanceError
            }
            onClick={handleCalculator}
          >
            {t('calculator.calculate')}
          </button>
          {new BigNumber(mulEntryQuantity).gt(leveOpenSort[0]?.max) && leverage > 1 && (
            <div className={cx('error-maximum')}>{t('calculator.exceeded')}</div>
          )}
        </>
      )}
    </div>
  );
};
