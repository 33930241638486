import React, { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { downFilterDark, downFilterLight, IConDownDefault, iconSearchSelect, iconX } from 'src/assets/icon';
import useOnClickOutside from 'src/hooks/useClickOutside';
import styled from '@emotion/styled';
import { useAppSelector } from 'src/store/hooks';
import useScreen, { useWindowSize } from 'src/hooks/useScreen';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
// import CancelAllOrderModal from '../CancelAllOrderModal';

type Props = {
  isSearch?: boolean;
  dataList: any;
  placeHolder?: any;
  isCancel?: any;
  customSelect?: any;
  className?: any;
  setValueSideFilter: Dispatch<SetStateAction<string>>;
  isReset: number;
  setIsReset?: Dispatch<SetStateAction<number>>;
  width: number;
  handleFilter?: any
};

const cx = classnames.bind(styles);

type SelectType = {
  customSelect?: any;
  openSelect?: boolean;
  placeHolder?: any;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  optionSelect?: any;
  theme: string;
  isMobile?: boolean;
  size: number;
  width: number;
};

type SelectWrapperType = {
  theme: string;
  isMobile?: boolean;
  size: number;
  width: number;
};

type SelectFilterType = {
  theme: string;
  size: number;
};

type SelectFilterActionType = {
  theme: string;
  size: number;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
};

const FilterAction = styled.div<SelectFilterActionType>`
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#ffffff' : '#333333')};
  display: flex;
  & .iconDown {
    path {
      stroke: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#ffffff')};
    }
  }
`;

const SelectValueWrapper = styled.div<SelectWrapperType>`
  background: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#f8f8f8' : '#1B1D24')};
  border-radius: 8px;
  padding: 14px 15px;
  width: ${(props) => (props.isMobile || props.size <= 970 ? 'auto' : `${props.width}px`)};
  min-width: ${(props) => (props.isMobile || props.size <= 970 ? 'auto' : `${props.width}px`)};
  display: flex;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile || props.size <= 970 ? '14px' : '18px')};
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6d7387')};
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
`;

const PlaceHolder = styled.div<SelectFilterType>`
  display: block;
  font-size: ${(props) => (props.size <= 970 ? '14px' : '18px')};
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6D7387')};
`;

const FilterName = styled.div<SelectFilterType>`
  width: auto;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#ffffff')};
`;

const FilterIcon = styled.div`
  margin-left: 9px;
`;

const fn = (text: string, count: number) => {
  return text?.slice(0, count) + (text?.length > count ? '...' : '');
};

const SelectValue = (props: SelectType) => {
  const { t } = useTranslation('common');
  return (
    <SelectValueWrapper
      isMobile={props.isMobile}
      theme={props.theme}
      onClick={() => props.setOpenSelect(!props.openSelect)}
      size={props.size}
      width={props.width}
    >
      <PlaceHolder size={props.size} theme={props.theme}>
        {props.placeHolder}
      </PlaceHolder>
      <FilterAction size={props.size} setOpenSelect={props.setOpenSelect} theme={props.theme}>
        <FilterName size={props.size} theme={props.theme}>
          {props.isMobile && props.size != 280
            ? fn(t(props?.optionSelect?.name), 6)
            : props.size === 280
            ? fn(t(props?.optionSelect?.name), 1)
            : t(props?.optionSelect?.name)}
        </FilterName>
        <FilterIcon>
          <img
            className={cx(props.openSelect && 'rotate')}
            src={props.theme === THEME_MODE.LIGHT ? downFilterLight : downFilterDark}
          />
        </FilterIcon>
      </FilterAction>
    </SelectValueWrapper>
  );
};

const SelectValueNoFirstWrapper = styled.div<SelectWrapperType>`
  border-radius: 8px;
  padding: 14px 0px;
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile || props.size <= 970 ? '14px' : '18px')};
  gap: 10px;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6d7387')};
  cursor: pointer;
  justify-content: flex-end;
  user-select: none;
  margin-left: 22px;
`;

const SelectValueNoFirst = (props: SelectType) => {
  const { t } = useTranslation('common');
  return (
    <SelectValueNoFirstWrapper
      size={props.size}
      theme={props.theme}
      onClick={() => props.setOpenSelect(!props.openSelect)}
      width={props.width}
    >
      <PlaceHolder size={props.size} theme={props.theme}>
        {t(props.placeHolder)}
      </PlaceHolder>
      <FilterIcon theme={props.theme}>
        <IConDownDefault className="iconDown" />
      </FilterIcon>
    </SelectValueNoFirstWrapper>
  );
};

export const SelectFilterSideOrder: React.FC<Props> = ({
  dataList,
  isSearch,
  placeHolder,
  isCancel,
  customSelect,
  className,
  setValueSideFilter,
  isReset,
  setIsReset,
  handleFilter,
  width,
}) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { isMobile } = useScreen();
  const [openSelect, setOpenSelect] = useState(false);
  const [optionSelect, setOptionSelect] = useState(dataList[0]);
  const el = useRef(null);
  const [textSearch, setTextSearch] = useState('');
  const [dataSelect, setDataSelect] = useState(dataList);
  const size = useWindowSize();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (isReset !== 0) {
      setOptionSelect(dataList[0]);
      setValueSideFilter('');
    }
  }, [dataList, isReset, setValueSideFilter]);

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = dataList.filter(
      (item: any) => item.name && item.name.toLowerCase().includes(text.toLowerCase()),
    );
    setDataSelect(resultSearch);
  };

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const [isOpenCancel, setIsOpenCancel] = useState(false);

  return (
    <div className={cx('select-wrapper', className)} ref={el}>
      {!isCancel ? (
        <SelectValue
          setOpenSelect={setOpenSelect}
          customSelect={customSelect}
          openSelect={openSelect}
          placeHolder={placeHolder}
          optionSelect={optionSelect}
          theme={theme}
          isMobile={isMobile}
          size={size[0]}
          width={width}
        />
      ) : (
        <SelectValueNoFirst
          width={width}
          size={size[0]}
          theme={theme}
          openSelect={openSelect}
          setOpenSelect={setOpenSelect}
        />
      )}

      {openSelect && (
        <div className={cx('select-list')}>
          {isSearch && (
            <div style={{ padding: '15px 15px 0px 15px' }}>
              <div className={cx('select-search')}>
                <input
                  placeholder={t('search_symbol')}
                  value={textSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <img src={iconX} onClick={() => setTextSearch('')} style={{ cursor: 'pointer' }} />
                <img src={iconSearchSelect} />
              </div>
            </div>
          )}

          {dataSelect.length > 0 ? (
            dataSelect?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {!isCancel ? (
                    <div
                      className={cx('select-item', item.value === optionSelect?.value && 'select-item-active')}
                      onClick={() => {
                        setOptionSelect(item);
                        setOpenSelect(false);
                        setValueSideFilter(item.value);
                        handleFilter();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {t(item.name)}
                    </div>
                  ) : (
                    <>
                      <div
                        className={cx('select-item')}
                        onClick={() => {
                          setIsOpenCancel(true);
                          setOpenSelect(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {' '}
                        {t(item.name)}
                      </div>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div className={cx('no-result')}>{t('pair.no_result_found')}</div>
          )}
        </div>
      )}
    </div>
  );
};
