import classnames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { facebookIcon, logoMonas, teleIcon, twitterIcon, youtubeIcon } from 'src/assets/icon';
import { getLocalStorage } from 'src/helpers/storage';
import useScreen from 'src/hooks/useScreen';
import { AboutUs, footerLinks } from './config';

import styles from './Footer.module.scss';

const cx = classnames.bind(styles);

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { isMobile, isTablet } = useScreen();
  const masterdata = getLocalStorage('masterdata');
  const social_networks = masterdata?.social_networks || null;

  const settingSite = (key: string) => {
    if (!masterdata) return '';
    const resultSetting = masterdata?.settings.filter((item: any) => item.key === key);

    return resultSetting.length > 0 ? resultSetting[0].value : '';
  };

  return (
    <div className={cx('footer')}>
      {!isMobile && !isTablet && (
        <div className={cx('footer-logo')}>
          <div className={cx('logo')}>
            <img src={logoMonas} alt="logo" />
          </div>
          <div className={cx('footer-text')}>
            <div className={cx('text')}>{settingSite('copyright')}</div>
          </div>
        </div>
      )}
      {isTablet && (
        <div className={cx('footer-logo')}>
          <div className={cx('logo-box')}>
            <div className={cx('logo')}>
              <img src={logoMonas} alt="logo" />
              <div className={cx('social-icon-mb')}>
                {social_networks &&
                  social_networks.map((item: any, index: number) => (
                    <a href={item.link} className={item.icon_class} target="_blank" key={index} rel="noreferrer">
                      {item.type === 'Twitter' && <img src={twitterIcon} width={24} />}
                      {item.type === 'Youtube' && <img src={youtubeIcon} width={24} />}
                      {item.type === 'Facebook' && <img src={facebookIcon} width={24} />}
                      {item.type === 'Telegram' && <img src={teleIcon} width={24} />}
                    </a>
                  ))}
              </div>
            </div>
            <div className={cx('footer-text')}>
              <div className={cx('text')}>{settingSite('copyright')}</div>
            </div>
            <div className={cx('line')}></div>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', gap: '77px' }}>
        <div className={cx('links-group', 'about-us')}>
          <div className={cx('links-title')}>{t(`footer.about_us`)}</div>
          {AboutUs.map(({ label, href, isBlank }) => (
            <div className={cx('links-item')} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx('link-item-href')}
                  target={isBlank ? '_blank' : '_parent'}
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
        </div>
        <div>
          <div className={cx('middle', 'group-link')}>
            {footerLinks?.map((item) => (
              <div key={item.label} className={cx('links-group')}>
                <div className={cx('links-title')}>{t(`footer.${item.label}`)}</div>

                {item.items.map(({ label, href, isBlank }) => (
                  <div className={cx('links-item')} key={label}>
                    {href ? (
                      <a
                        href={href}
                        className={cx('link-item-href')}
                        target={isBlank ? '_blank' : '_parent'}
                        rel="noreferrer noopener"
                      >
                        {t(`footer.${label}`)}
                      </a>
                    ) : (
                      <div>{t(`footer.${label}`)}</div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <div className={cx('links-group')}>
              <div className={cx('links-title')}> {t('footer.contact_us')}</div>
              <div className={cx('links-item')}>
                <a className={cx('link-item-href')}>{settingSite('contact_email')}</a>
              </div>
            </div>
          </div>
          <div className={cx('links-group-2')}>
            <div className={cx('title-contact')}>{t('footer.contact_us')}</div>
            <a className={cx('text-contact')}>{settingSite('contact_email')}</a>
          </div>
        </div>
      </div>

      {!isMobile && !isTablet && (
        <div className={cx('social-icon')}>
          {social_networks &&
            social_networks.map((item: any, index: number) => (
              <a href={item.link} className={item.icon_class} target="_blank" key={index} rel="noreferrer">
                {item.type === 'Twitter' && <img src={twitterIcon} width={32} />}
                {item.type === 'Youtube' && <img src={youtubeIcon} width={32} />}
                {item.type === 'Facebook' && <img src={facebookIcon} width={32} />}
                {item.type === 'Telegram' && <img src={teleIcon} width={32} />}
              </a>
            ))}
        </div>
      )}
    </div>
  );
};

export default Footer;
