export const dataMinutes = [
  { value: '1', label: 'tradingView.minute_ops', time: '1' },
  { value: '3', label: 'tradingView.minutes_ops', time: '3' },
  { value: '5', label: 'tradingView.minutes_ops', time: '5' },
  { value: '15', label: 'tradingView.minutes_ops', time: '15' },
  { value: '30', label: 'tradingView.minutes_ops', time: '30' },
];

export const dataHours = [
  { value: '60', label: 'tradingView.hour_ops', time: '1' },
  { value: '120', label: 'tradingView.hours_ops', time: '2' },
  { value: '240', label: 'tradingView.hours_ops', time: '4' },
  { value: '360', label: 'tradingView.hours_ops', time: '6' },
  { value: '720', label: 'tradingView.hours_ops', time: '12' },
];

export const TRADING_VIEW_CHART = 'trading-view';
export const DEPTH_CHART = 'depth';

export const TIME_WEBVIEW = [
  {
    label: '15m',
    value: '15',
  },
  {
    label: '30m',
    value: '30',
  },
  {
    label: '1H',
    value: '60',
  },
  {
    label: '4H',
    value: '240',
  },
  {
    label: '1D',
    value: '1D',
  },
  {
    label: '1W',
    value: '1W',
  },
  {
    label: '1M',
    value: '1M',
  },
];
