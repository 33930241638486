import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DISPLAY_RECORD_NUMBER } from 'src/constants/common.constants';
import axiosInstance from 'src/helpers/config';
import { IFunding } from 'src/interfaces/funding';

const initialState = {
  error: '',
  loading: false,
  funding: { code: 0, data: [] as IFunding[], metadata: { totalPage: 1 } },
  firstLoad: true,
};

export const getFundingBySymbol = createAsyncThunk('get-funding-by-symbol', async (symbol: string, thunkAPI: any) => {
  try {
    if (thunkAPI.getState().funding.firstLoad)
      return await axiosInstance.get(`/funding/history`, {
        params: {
          symbol,
          page: 1,
          size: DISPLAY_RECORD_NUMBER,
        },
      });
    else return thunkAPI.getState().funding.funding;
  } catch (error: any) {
    throw error;
  }
});

export const fundingSlice = createSlice({
  name: 'funding',
  initialState,
  reducers: {
    setFirstLoadFunding: (state, action) => {
      state.firstLoad = action.payload;
    },
    resetInitStateFunding: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
  extraReducers: {
    [`${getFundingBySymbol.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getFundingBySymbol.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.firstLoad = true;
    },
    [`${getFundingBySymbol.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.funding = action.payload;
      state.firstLoad = false;
    },
  },
});

const { reducer: fundingReducer } = fundingSlice;

export default fundingReducer;

export const { setFirstLoadFunding, resetInitStateFunding } = fundingSlice.actions;
