import classnames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { formatNumber, formatPrice, getValueClassName } from 'src/helpers/numberFormatter';
import { useCountdown } from 'src/helpers/useCountDown';
import styles from 'src/pages/Trading/Dashboard.module.scss';
import { getTicker24h } from 'src/services/ticker';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import TooltipText from 'src/components/cores/TooltipText';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useTranslation } from 'react-i18next';
import { getIconPriceChange } from 'src/features/Market/components/ChooseMarketModal/ChooseMarketModal';

const cx = classnames.bind(styles);

const PairStatisticsMobile: React.FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const ticker = useAppSelector((state) =>
    state.ticker.tickers.find((ticker) => ticker.symbol === state.instrument.currentInstrument.symbol),
  );
  const tickerLoading = useAppSelector((state) => state.ticker.loading);
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const decimal = -Math.ceil(Math.log10(Number(instrument?.minPriceMovement ?? '0.01')));

  const [needScroll, setNeedScroll] = useState(false);
  const [transformX, setTransformX] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [hours, minutes, seconds] = useCountdown(ticker?.nextFunding);

  const setScroll = () => {
    const contentWidth = contentRef.current?.offsetWidth || 0;
    const parentWidth = parentRef.current?.offsetWidth || 0;

    if (contentWidth <= parentWidth) {
      setTransformX(0);
    } else {
      if (transformX != 0) {
        setTransformX(parentWidth - contentWidth);
      }
    }

    setNeedScroll(contentWidth > parentWidth);
  };

  useEffect(() => {
    dispatch(getTicker24h());
  }, []);

  useEffect(() => {
    setScroll();
  }, [parentRef.current?.offsetWidth]);

  useEffect(() => {
    if (!needScroll) {
      const contentWidth = contentRef.current?.offsetWidth || 0;
      const parentWidth = parentRef.current?.offsetWidth || 0;
      setNeedScroll(contentWidth > parentWidth);
    }
  }, [contentRef.current?.offsetWidth]);

  return (
    <>
      <div className={cx('box_container', 'hidden-pair-mb')}>
        <div className={cx('pair-statistics')} ref={parentRef}>
          <div className={cx('statistics-wrapper')}>
            <div
              className={cx('statistics-container', 'scroll-mb')}
              style={{ transform: `translateX(${transformX}px)` }}
              ref={contentRef}
            >
              <div className={cx('statistic-item')}>
                <TooltipText text={t('pair.latest_mark_price_noti')}>
                  <span className={cx('statistic-item-label')}>{t('pair.mark_price')}</span>
                </TooltipText>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber characters={8} decimal={decimal} defaultValue={ticker?.oraclePrice} />
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <TooltipText text={`${t('pair.index_price_noti')} ${formatPrice(ticker?.indexPrice, instrument)}`}>
                  <span className={cx('statistic-item-label')}>{t('pair.index_price')}</span>
                </TooltipText>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber characters={8} decimal={decimal} defaultValue={ticker?.indexPrice} />
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <TooltipText text={t('pair.the_payment_noti')}>
                  <span className={cx('statistic-item-label')}>
                    {' '}
                    {t('pair.funding')}/{t('pair.count_down')}
                  </span>
                </TooltipText>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value', 'flex')}>
                    <div className={cx('funding')}>{formatNumber(Number(ticker?.fundingRate), 4, '')}%</div>
                    <div className={cx('count-down')}>
                      {hours}:{minutes}:{seconds}
                    </div>
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <span className={cx('statistic-item-label')}>{t('pair.24h_change')}</span>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value', getValueClassName(ticker?.priceChange))}>
                    <Box display="flex" alignItems="center">
                      {ticker?.priceChange ? (
                        <TooltipNumber
                          defaultValue={Math.abs(Number(ticker?.priceChange))}
                          characters={8}
                          decimal={decimal}
                        />
                      ) : (
                        '--'
                      )}
                      &nbsp;(
                      {ticker?.priceChangePercent ? (
                        <>
                          {getIconPriceChange(ticker?.priceChangePercent)}{' '}
                          <TooltipNumber
                            defaultValue={Math.abs(Number(ticker?.priceChangePercent))}
                            characters={7}
                            decimal={DECIMAL_TWO}
                            symbol="%"
                          />
                        </>
                      ) : (
                        '--'
                      )}
                      )
                    </Box>
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <span className={cx('statistic-item-label')}>{t('pair.24h_high')}</span>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber defaultValue={ticker?.highPrice} characters={8} decimal={decimal} />
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <span className={cx('statistic-item-label')}>{t('pair.24h_low')}</span>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber defaultValue={ticker?.lowPrice} characters={8} decimal={decimal} />
                  </span>
                )}
              </div>

              <div className={cx('statistic-item')}>
                <span className={cx('statistic-item-label')}>
                  {t('pair.24h_volume')} ({instrument.rootSymbol})
                </span>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber defaultValue={ticker?.volume} characters={12} decimal={DECIMAL_TWO} />
                  </span>
                )}
              </div>
              <div className={cx('statistic-item')}>
                <span className={cx('statistic-item-label')}>
                  {t('pair.24h_volume')}({isCoinM ? 'Cont' : instrument.quoteCurrency})
                </span>
                {tickerLoading ? (
                  <div className={cx('statistic-item-last-price')}>0</div>
                ) : (
                  <span className={cx('statistic-item-value')}>
                    <TooltipNumber defaultValue={ticker?.quoteVolume} characters={12} decimal={DECIMAL_TWO} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PairStatisticsMobile;
