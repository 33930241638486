import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';

type IFundingRate = {
  id: string;
  symbol: string;
  fundingRate: string;
  createdAt: string;
};

const initialState = {
  error: '',
  loading: false,
  fundingRate: { code: 0, data: [] as IFundingRate[], metadata: { totalPage: 1 } },
};

export const getFundingRate = createAsyncThunk(
  'get-funding-rate',
  async (params: { symbol: string; from?: number; to?: number }) => {
    return await axiosInstance.get(`/funding/rate/${params.symbol}`, {
      params: {
        from: params.from,
        to: params.to,
      },
    });
  },
);

export const fundingRateSlice = createSlice({
  name: 'fundingRate',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getFundingRate.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getFundingRate.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getFundingRate.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.fundingRate = action.payload;
    },
  },
});

const { reducer: fundingRateReducer } = fundingRateSlice;

export default fundingRateReducer;
