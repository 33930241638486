import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames/bind';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import checkBoxDefaultIcon from 'src/assets/icon/checkbox.svg';
import checkedIcon from 'src/assets/icon/checked.svg';
import styles from 'src/features//Market/components/DialogChooseMarginMode/DialogChooseMarginMode.module.scss';
import iconClose from 'src/assets/icon/icon_Close.svg';
import iconCloseDialogDark from 'src/assets/icon/dark/icon-close-dialog-dark.svg';
import axiosInstance from 'src/helpers/config';
import { useAppSelector } from 'src/store/hooks';
import dangerousICon from 'src/assets/icon/dangerous.svg';
import { THEME_MODE } from 'src/interfaces/theme';
import { getCookieStorage } from 'src/helpers/storage';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { uncheckDark } from 'src/assets/icon';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles);

interface PropsChooseMarginMode {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  marginMode: string;
  leverage: number;
}

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const MARGIN_MODE = {
  CROSS: 'CROSS',
  ISOLATE: 'ISOLATE',
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  return (
    <DialogTitle sx={{ padding: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={theme === THEME_MODE.LIGHT ? iconClose : iconCloseDialogDark} alt="icon-close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogChooseMarginMode: React.FC<PropsChooseMarginMode> = ({ isOpen, setIsOpen, marginMode, leverage }) => {
  const { t } = useTranslation('common');
  const instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const listPosition = useAppSelector((state) => state.position.positions);
  const [isLogined, setIsLogined] = useState(false);
  const orders = useAppSelector((state) => state.order.orders);
  const [isCross, setIsCross] = useState(marginMode ? marginMode === MARGIN_MODE.CROSS : false);
  const [isAllowChange, setIsAllowChange] = useState(false);

  const account = useSelector((state: RootState) => state.account.account);
  const token = getCookieStorage('access_token');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  useEffect(() => {
    if (token && token !== 'undefined') {
      setIsLogined((account && Object.keys(account).length > 0) || false);
    } else {
      setIsLogined(false);
      setIsCross(false);
    }
  }, [account, token]);

  const handleConfirmChooseMarginMode = async () => {
    try {
      const params = {
        marginMode: isCross ? MARGIN_MODE.CROSS : MARGIN_MODE.ISOLATE,
        instrumentId: Number(instrument.id),
        leverage: leverage,
      };
      await axiosInstance.post('/marginMode', params);
      // dispatch(getOrderMode(Number(instrument.id)));
      setIsOpen(false);
      // noti.success({
      //   title: 'Margin Mode adjusted successfully',
      // });
    } catch (error) {}
  };

  const handleCloseChooseMarginModeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const contractPosition = listPosition.filter((item) => item.symbol === instrument.symbol);
    const contractOpen = orders.filter((item) => item.symbol === instrument.symbol);
    if (contractPosition.length > 0 || contractOpen.length > 0) {
      setIsAllowChange(false);
    } else {
      setIsAllowChange(true);
    }
  }, [listPosition, orders]);

  return (
    <BootstrapDialog
      onClose={handleCloseChooseMarginModeDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <div className={cx('dialog-wrapper')}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseChooseMarginModeDialog}>
          <div className={cx('title-dialog')}>
            {instrument?.name} {t('trading.perpetual_margin_mode')}
          </div>
        </BootstrapDialogTitle>

        <div className={cx('select-items')}>
          <div
            className={cx(`select-item${isCross ? '-checked' : ''}`)}
            onClick={() => {
              setIsCross(true);
            }}
          >
            <div className={cx('text-choose-mode')}>{t('trading.cross')}</div>
            {isCross ? (
              <img src={checkedIcon} alt="checkedIcon" />
            ) : (
              <img src={theme === THEME_MODE.LIGHT ? checkBoxDefaultIcon : uncheckDark} alt="checkBoxDefaultIcon" />
            )}
          </div>
          <div
            className={cx(`select-item${!isCross ? '-checked' : ''}`)}
            onClick={() => {
              setIsCross(false);
            }}
          >
            <div className={cx('text-choose-mode')}>{t('trading.isolated')}</div>
            {!isCross ? (
              <img src={checkedIcon} alt="checkedIcon" />
            ) : (
              <img src={theme === THEME_MODE.LIGHT ? checkBoxDefaultIcon : uncheckDark} alt="checkBoxDefaultIcon" />
            )}
          </div>
        </div>
        <DialogContent>
          <Typography gutterBottom>
            <div className={cx('title')}>{t('trading.switching_margin_mode')}</div>
          </Typography>
          <Typography gutterBottom>
            <div className={cx('description')}>
              <b>{t('trading.cross_margin_mode')}:</b> {t('trading.cross_positions_noti')}
            </div>
          </Typography>
          <Typography gutterBottom>
            <div className={cx('description', 'no-margin')}>
              <b>{t('trading.isolated_margin_mode')}:</b> {t('trading.manage_your_risk')}
            </div>
          </Typography>
          {!isAllowChange && (
            <Typography gutterBottom>
              <div className={cx('error-message')}>
                <div>
                  <img src={dangerousICon} alt="dangerousICon" />
                </div>
                <div className={cx('message')}>{t('trading.mess_margin')}</div>
              </div>
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={cx('button-modal')}>
          <button onClick={handleConfirmChooseMarginMode} disabled={!isAllowChange || !isLogined}>
            {t('trading.confirm')}
          </button>
        </DialogActions>
      </div>
    </BootstrapDialog>
  );
};

export default DialogChooseMarginMode;
