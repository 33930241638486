import { ClickAwayListener, Popper } from '@material-ui/core';
import classnames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType } from 'src/features/Market/Constant';
import styles from './StopModal.module.scss';

const cx = classnames.bind(styles);

interface StopModalProps {
  open: boolean;
  refElm: HTMLImageElement | null;
  handleClose: any;
  handleChooseOption: any;
  selectedValue?: any;
  option: any;
}

const StopModal: React.FC<StopModalProps> = ({
  open,
  handleClose,
  refElm,
  handleChooseOption,
  selectedValue,
  option,
}) => {
  const { t } = useTranslation('common');
  const handleChoose = (value: OrderType) => {
    handleChooseOption(value);
    handleClose();
  };

  const handleClosePopover = () => {
    handleClose();
  };

  return (
    <>
      {open && (
        <ClickAwayListener onClickAway={handleClosePopover}>
          <Popper
            open={open}
            anchorEl={refElm}
            transition
            placement="bottom"
            disablePortal={false}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            className={cx('modal-paper')}
          >
            <div>
              {option.map((item: any) => {
                return (
                  <div
                    key={item.value}
                    className={selectedValue === item.value ? cx('modal-item', 'active') : cx('modal-item')}
                    onClick={() => {
                      handleChoose(item.value);
                    }}
                  >
                    {/* {item.label} */}
                    {t(`${item.label}`)}
                  </div>
                );
              })}
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default StopModal;
