import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';

export type IWithdrawFeeSetting = {
  amount: string;
  fee: string;
};

const initialState = {
  loading: false,
  error: false,
  feeWithdraw: '0',
  minimumWithdraw: '0',
};

export const getWithdrawFee = createAsyncThunk('getWithdrawFee', async () => {
  return await axiosInstance.get(`/setting/withdrawal-fee`);
});

export const getWithdrawMinimal = createAsyncThunk('getWithdrawMinimal', async () => {
  return await axiosInstance.get(`/setting/minimum-withdrawal`);
});

export const withdraw = createAsyncThunk('/withdraw', async (params: { amount: string; fee: string }) => {
  return await axiosInstance.post('/account/withdraw', params);
});

export const userSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {},
  extraReducers: {
    // getWithdrawFee
    [`${getWithdrawFee.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getWithdrawFee.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getWithdrawFee.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.feeWithdraw = action.payload.data.value;
    },

    // getWithdrawMinimal
    [`${getWithdrawMinimal.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getWithdrawMinimal.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getWithdrawMinimal.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.minimumWithdraw = action.payload.data.value;
    },

    // withdraw
    [`${withdraw.pending}`]: (state) => {
      state.loading = true;
    },
    [`${withdraw.rejected}`]: (state) => {
      state.loading = false;
    },
    [`${withdraw.fulfilled}`]: (state) => {
      state.loading = false;
    },
  },
});

const { reducer: withdrawReducer } = userSlice;

export default withdrawReducer;
