import { makeStyles, Tooltip, Zoom } from '@material-ui/core';
import React, { useState } from 'react';
import { formatPriceRoundDown } from 'src/helpers/numberFormatter';

export const DECIMAL_TWO = 2;
export const DECIMAL_THREE = 3;
export const DECIMAL_FOUR = 4;

export const useStyles = makeStyles(() => ({
  popper: {
    color: 'var(--bg-tooltip)',
  },
  customTooltip: {
    backgroundColor: 'var(--bg-tooltip-1)',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px',
    lineHeight: '150%',
    borderRadius: '8px',
  },
  // customArrow: {
  //   color: 'var(--bg-tooltip-1)',
  // },
}));

interface Props {
  customText?: any;
  defaultValue: any;
  characters: number;
  instrument?: any;
  decimal?: any;
  symbol?: any;
}

const TooltipNumber: React.FC<Props> = ({ customText, defaultValue, symbol, decimal, characters, instrument }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const tickSize = Number(instrument?.tickSize || '0.01');
  const precision = instrument ? Math.abs(Math.ceil(Math.log10(Number(tickSize)))) : decimal;
  const numberFinal = formatPriceRoundDown(defaultValue, precision);
  const totalCommas = (numberFinal.match(/,/g) || []).length;
  const totalDot = (numberFinal.match(/./g) || []).length;

  const handleCharacter = () => {
    const numberSpace = Math.ceil(characters / 3) - 1;
    if (totalCommas >= numberSpace) {
      return numberSpace;
    } else {
      if (totalCommas + totalDot >= numberSpace) return numberSpace;
      return totalCommas + totalDot;
    }
  };

  const formatHoverVal = () => {
    const newStr = numberFinal.slice(0, characters + handleCharacter());
    return newStr.concat('...');
  };

  return (
    <>
      {numberFinal.length - handleCharacter() > characters ? (
        <Tooltip
          classes={{
            tooltip: classes.customTooltip,
            touch: classes.popper,
          }}
          /* @ts-ignore */
          TransitionComponent={Zoom}
          placement="bottom"
          title={numberFinal}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          {/* @ts-ignore */}
          <div onClick={() => setIsOpen(true)} onBlur={() => setIsOpen(false)} className={customText}>
            {formatHoverVal()} <span>{symbol}</span>
          </div>
        </Tooltip>
      ) : (
        <div className={customText}>
          {numberFinal} <span>{symbol}</span>
        </div>
      )}
    </>
  );
};

export default TooltipNumber;
