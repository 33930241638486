import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation, BrowserRouter as Router } from 'react-router-dom';
import NavBar from 'src/components/NavBar/NavBar';
import routers from 'src/routes/routes';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import styles from 'src/styles/App.module.scss';
import { getCookieStorage } from './helpers/storage';
import { getOrderMode } from './services/account';
import { getInstrument } from './services/instrument';
import { BaseSocket } from 'src/socket/BaseSocket';
import Footer from './components/Footer/Footer';
import { getMasterData } from './services/masterdata';
import { getLeverageMargin } from './services/general';
import { getMasterDataFuture } from './services/masterdataFuture';
import { TypeTrade } from './constants/common.constants';
import { Helmet } from 'react-helmet';
import { formatPriceRoundDown } from './helpers/numberFormatter';

const App: React.FC<any> = () => {
  const theme = useAppSelector((state) => state.theme.themeMode);
  const ticker = useAppSelector((state) =>
    state.ticker.tickers.find((ticker) => ticker.symbol === state.instrument.currentInstrument.symbol),
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  document.documentElement.setAttribute('data-theme', theme);
  const isExpired = getCookieStorage('isExpired');
  const instrument = useAppSelector((state) => state.instrument.currentInstrument);

  const checkRenderSidebar = (path: string) => {
    return !!['/account'].find((i) => path.indexOf(i) !== -1);
  };

  const token = getCookieStorage('access_token');
  useEffect(() => {
    if (location.pathname === '/') {
      history.push(`/futures/${TypeTrade.USD_M}`);
    }
    dispatch(getInstrument());
    dispatch(getMasterData());
    dispatch(getMasterDataFuture());
    BaseSocket.getInstance().connect();
  }, []);

  useEffect(() => {
    if (token && instrument.id && isExpired === 'false') {
      dispatch(getOrderMode(Number(instrument.id)));
      dispatch(getLeverageMargin(instrument.symbol));
    }
  }, [instrument]);

  const tickSize = Number(instrument?.minPriceMovement || '0.01');
  const precision = Math.abs(Math.ceil(Math.log10(Number(tickSize))));

  document.title =
    instrument && ticker
      ? `${formatPriceRoundDown(ticker?.lastPrice || 0, precision)} | ${instrument.name || 'BTCUSDT'} ${' '} ${
          instrument.contractType === TypeTrade.USD_M ? 'USDⓈ-M' : 'COIN'
        }-Margined Perpetual | Monas Futures`
      : 'Monas';

  return (
    <div
      id="body"
      className="App"
      style={{ backgroundColor: location.pathname.includes('webview') ? 'transparent' : '' }}
    >
      {/* {instrument && ticker && (
        <Helmet>
          <title>
            {formatPriceRoundDown(ticker?.lastPrice || 0, precision)} | {instrument.name || 'BTCUSDT'}{' '}
            {instrument.contractType === TypeTrade.USD_M ? 'USDⓈ-M' : 'COIN'}-Margined Perpetual | Monas Futures{' '}
          </title>
        </Helmet>
      )} */}

      {location.pathname.includes('webview') ? (
        <div style={{ height: '100vh' }}>
          <React.Suspense fallback={<div>....Loading</div>}>
            <>
              {Object.keys(routers).map((key) => {
                //@ts-ignore
                const route = routers[key];
                return <route.route key={route.path} {...route} />;
              })}
              {/* <Route path="*" /> */}
            </>
          </React.Suspense>
        </div>
      ) : (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <div className={styles.navbar}>
            <NavBar />
          </div>
          <div className={checkRenderSidebar(location.pathname) ? styles.contentFlex : styles.content} id="main">
            <React.Suspense fallback={<div>....Loading</div>}>
              <>
                {Object.keys(routers).map((key) => {
                  //@ts-ignore
                  const route = routers[key];
                  return <route.route key={route.path} {...route} />;
                })}
                {/* <Route path="*" /> */}
              </>
            </React.Suspense>
          </div>
          {!location.pathname.includes('futures') && (
            <div className={styles.footer}>
              <Footer />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
