import { Dialog, DialogContent } from '@material-ui/core';
import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { deviceImg, deviceImgMb } from 'src/assets/icon';
import { handleDirect } from 'src/features/PositionAndHistory/PositionRecord';
import { useAuth } from 'src/hooks/useAuth';
import useScreen from 'src/hooks/useScreen';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const DeviceManagementModal = ({ open }: { open: boolean }) => {
  const { logout } = useAuth();
  const { isMobile } = useScreen();
  const { t } = useTranslation('common');

  return (
    <Dialog className={cx('wrapper')} maxWidth={'md'} open={open}>
      <DialogContent className={cx('content')}>
        <div>
          <img src={isMobile ? deviceImgMb : deviceImg} />
        </div>
        <div className={cx('text-one')}>{t('transfer.current_session_terminated')}</div>
        <div className={cx('text-two')}>{t('header.login_again')}</div>
        <button
          onClick={async () => {
            await logout();
            handleDirect('login');
          }}
        >
          {t('header.login')}
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceManagementModal;
