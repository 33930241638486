import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { applyOrderbookUpdate, applyOrderbookUpdates } from 'src/features/OrderbookTrade/orderbookHelper';
import axiosInstance from 'src/helpers/config';
// import { openSnackbarError } from 'src/store/snackbar';
// import store from 'src/store/store';

export interface IOrderbook {
  bids: string[][];
  asks: string[][];
  updatedAt: number;
  lastUpdatedAt?: number;
  symbol?: string;
}

const initialState: { orderbook: IOrderbook; isReady: boolean; pendingUpdates: IOrderbook[]; selectedOrder: any } = {
  orderbook: {
    bids: [],
    asks: [],
    updatedAt: Date.now(),
  },
  isReady: false,
  pendingUpdates: [],
  selectedOrder: null,
};

export const getOrderbook = createAsyncThunk('getOrderbook', async (symbol: string) => {
  const res = axiosInstance
    .get(`/orderbook/${symbol}`)
    .catch((error) => {
      // store.dispatch(openSnackbarError({ message: error.response.data.message, title: 'TODO' }));
      throw error;
    })
    .then((res) => res);
  return res;
});

export const orderbookSlice = createSlice({
  name: 'orderbook',
  initialState,
  reducers: {
    clearOrderbook: (state) => {
      // state.orderbook = { ...initialState.orderbook };
      state.isReady = false;
      state.pendingUpdates = [];
    },
    removeOrderbook: (state) => {
      state.orderbook = { ...initialState.orderbook };
      state.isReady = false;
      state.pendingUpdates = [];
    },
    updateOrderbook: (state, action) => {
      if (state.isReady) {
        const orderbook = { ...state.orderbook } as IOrderbook;
        applyOrderbookUpdate(orderbook, action.payload);
        state.orderbook = orderbook;
      } else {
        state.pendingUpdates = [...state.pendingUpdates, action.payload];
      }
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
  },
  extraReducers: {
    [getOrderbook.pending.toString()]: (state) => {
      state.isReady = false;
    },
    [getOrderbook.rejected.toString()]: () => {},
    [getOrderbook.fulfilled.toString()]: (state, action) => {
      const orderbook = action.payload.data;
      applyOrderbookUpdates(orderbook, state.pendingUpdates);
      state.orderbook = orderbook;
      state.isReady = true;
      state.pendingUpdates = [];
    },
  },
});

const { reducer: orderbookReducer } = orderbookSlice;

export default orderbookReducer;

export const { clearOrderbook, removeOrderbook, updateOrderbook, setSelectedOrder } = orderbookSlice.actions;
