import {
  dashboardIcon,
  earnIcon,
  englishIcon,
  faqIcon,
  futureIcon,
  koreaIcon,
  noticeIcon,
  overviewIcon,
  referralIcon,
  rewardIcon,
  savingIcon,
  securityIcon,
  spotIcon,
  stakingIcon,
  transactionIcon,
  vietnamIcon,
} from 'src/assets/icon';

export enum TRADE_OPTIONS {
  TRADE = 'Trade',
  SELL = 'Sell',
}

export enum OPTION_FEATURE {
  ACCOUNT = 'account',
  TRADE = 'Trade',
}

export enum EARN_OPTIONS {
  EARN = 'Earn',
  SELL = 'Sell',
}

export enum SUPPORT_OPTIONS {
  SUPPORT = 'Support',
  OTHER = 'Other',
}
export enum WALLET_OPTIONS {
  WALLET = 'Wallet',
  COLD_WALLET = 'Cold Wallet',
}
export const tradeOptions = [
  { value: TRADE_OPTIONS.TRADE, label: TRADE_OPTIONS.TRADE },
  { value: TRADE_OPTIONS.SELL, label: TRADE_OPTIONS.SELL },
];

export const earnOptions = [
  { value: EARN_OPTIONS.EARN, label: EARN_OPTIONS.EARN },
  { value: EARN_OPTIONS.SELL, label: EARN_OPTIONS.SELL },
];

export const languages = [
  { value: 'en', label: 'English', icon: englishIcon },
  { value: 'vi', label: 'Vietnam', icon: vietnamIcon },
];

export const earnOptionsList = [
  {
    icon: earnIcon,
    label: 'Earn',
  },
  {
    icon: savingIcon,
    label: 'Saving',
  },
  {
    icon: stakingIcon,
    label: 'Staking',
  },
];

export const supportOptions = [
  {
    icon: faqIcon,
    label: 'FAQ',
  },
  {
    icon: noticeIcon,
    label: 'Support Center',
  },
];

export const walletOptions = [
  {
    icon: overviewIcon,
    label: 'Overview',
  },
  {
    icon: spotIcon,
    label: 'Spot',
  },
  {
    icon: futureIcon,
    label: 'Futures',
  },
  {
    icon: transactionIcon,
    label: 'Transaction History',
  },
];

export const profileOptions = [
  {
    icon: dashboardIcon,
    label: 'Dashboard',
  },
  {
    icon: securityIcon,
    label: 'Security',
  },
  {
    icon: referralIcon,
    label: 'Referral',
  },
  {
    icon: rewardIcon,
    label: 'Reward Center',
  },
];
