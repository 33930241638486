import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { DISPLAY_RECORD_NUMBER } from 'src/constants/common.constants';
import axiosInstance from 'src/helpers/config';
import { CancelToken } from './transaction-history';

const initialState = {
  error: '',
  loading: false,
  ordersHistory: [],
  totalPage: 1,
};

type bodyGetOrderHistory = {
  startTime: string | number;
  endTime: string | number;
  side?: string | null;
  type?: string | null;
  symbol?: string | null;
  isActive?: boolean;
  status?: string | null;
  contractType: string;
};

let cancelOrderHistory: any;

export const getOrderHistory = createAsyncThunk(
  'getOrderHistory',
  async ({ params, payload }: { params: { page: number; size: number }; payload: bodyGetOrderHistory }) => {
    if (cancelOrderHistory != undefined) {
      cancelOrderHistory();
    }
    return await axiosInstance.post(`/order/history`, payload, {
      params: params,
      timeout: 60000,
      cancelToken: new CancelToken(function executor(c) {
        cancelOrderHistory = c;
      }),
    });
  },
);

export const orderSliceHistory = createSlice({
  name: 'order-history',
  initialState,
  reducers: {},
  extraReducers: {
    [`${getOrderHistory.pending}`]: (state) => {
      state.loading = true;
    },

    [`${getOrderHistory.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getOrderHistory.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.ordersHistory = action.payload.data;
      state.totalPage = action.payload.metadata.totalPage;
    },
  },
});

const { reducer: orderHistoryReducer } = orderSliceHistory;

export default orderHistoryReducer;
