import React, { useEffect, useState } from 'react';
import { TAB_CALCULATOR } from './constants';
import { LiquidationCalculator } from './LiquidationCalculator';
import { PNLCalculator } from './PNLCalculator';
import { SelectPair } from './SelectPair';
import { TargetPriceCalculator } from './TargetPriceCalculator';
import DialogContent from '@material-ui/core/DialogContent';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import classnames from 'classnames/bind';
import { OpenPriceCalculator } from './OpenPriceCalculator';
import { MaxOpenCalculator } from './MaxOpenCalculator';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Instrument } from 'src/services/instrument';
import useScreen from 'src/hooks/useScreen';
import { useParams } from 'react-router-dom';
import { setTheme } from 'src/services/theme';
import { THEME_MODE } from 'src/interfaces/theme';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { TypeTrade } from 'src/constants/common.constants';
import { changeTypeTrade } from 'src/services/typeTrade';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { IconNext } from 'src/assets/icon';
import { TabsMobile } from './TabsMobile';

const cx = classnames.bind(styles);

export const CalculatorMain = () => {
  const instrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const instruments: Instrument[] = useAppSelector((state) => state.instrument.instrument);
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const [tabActive, setTabActive] = useState(0);
  const [pairSelect, setPairSelect] = useState(instrument);
  const params = useParams<{ symbol: string; theme: string; typeTrade: string; lang: string }>();
  const dispatch = useAppDispatch();
  const [listCoins, setListCoin] = useState(instruments);
  const { t } = useTranslation('common');

  useEffect(() => {
    const list = instruments.filter((item) =>
      isCoinM ? item.contractType === TypeTrade.COIN_M : item.contractType === TypeTrade.USD_M,
    );
    setListCoin(list);
  }, [instruments, isCoinM]);

  useEffect(() => {
    if (location?.pathname.includes('webview')) {
      const { theme, typeTrade, lang } = params;

      const themeWebview = theme === 'light' ? THEME_MODE.LIGHT : THEME_MODE.DARK;
      dispatch(setTheme(themeWebview));
      dispatch(changeTypeTrade(typeTrade === TypeTrade.COIN_M));
      i18n.changeLanguage(lang);
    }
  }, [location?.pathname, params.theme]);

  useEffect(() => {
    if (location?.pathname.includes('webview') && listCoins.length > 0) {
      const { symbol } = params;
      const selectPair = listCoins.filter((item) => item.symbol === symbol);

      selectPair.length > 0 && setPairSelect(selectPair[0]);
    } else {
      setPairSelect(instrument);
    }
  }, [listCoins, isCoinM]);

  const { isMobile } = useScreen();

  return (
    <div id="calculator" className={cx('calculator')}>
      <SelectPair setPairSelect={setPairSelect} pairSelect={pairSelect} coins={listCoins} />

      {/*contents*/}
      <DialogContent className={cx('modal-content')}>
        {/* Tab here */}

        {isMobile ? (
          <div className={cx('tab-calculator-mb')}>
            <TabsMobile setTabActive={setTabActive} tabActive={tabActive} />
          </div>
        ) : (
          <div className={cx('tab-calculator')}>
            {TAB_CALCULATOR.map((item, index) => (
              <div
                className={cx('tab-item', tabActive === index && 'tab-item-active')}
                key={index}
                onClick={() => setTabActive(index)}
              >
                {t(item)}
              </div>
            ))}
          </div>
        )}

        {tabActive === 0 && <PNLCalculator pair={pairSelect} />}
        {tabActive === 1 && <TargetPriceCalculator pair={pairSelect} />}
        {tabActive === 2 && <LiquidationCalculator pair={pairSelect} />}
        {tabActive === 3 && <MaxOpenCalculator pair={pairSelect} />}
        {tabActive === 4 && <OpenPriceCalculator pair={pairSelect} />}
      </DialogContent>
    </div>
  );
};
